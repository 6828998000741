import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { FetchMethodType } from "../../../../../Utils/GlobalVariables/Fetch/MethodsType";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";

const MeasuringDeviceVerificationFormStepOne = (props) => {
  const { t } = useTranslation();

  const [workers, setWorkers] = useState([]);

  const loadWorkers = async () => {
    const { payload } = await GlobalAuthFetch({
      path: "/worker/all",
      method: FetchMethodType.get,
    });
    if (payload) {
      setWorkers(payload.data);
    }
  };

  useEffect(() => {
    loadWorkers();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:measuringDevice.verificationHeader")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:measuringDevice.dateOfReview")}*`}
            name="dateOfReview"
            data-cy="dateOfReview"
          />
        </Col>

        <Col xs={6}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:measuringDevice.nextCheckDate")}*`}
            name="nextCheckDate"
            data-cy="nextCheckDate"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:measuringDevice.result")}*`}
            name="result"
            data-cy="result"
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="approvedBy"
            component={BMXSelectField}
            values={workers.map((e) => ({
              value: e.uuid,
              name: `${e.firstName} ${e.lastName}`,
            }))}
            label={`${t("global:measuringDevice.approvedBy")}*`}
            name="approvedBy"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:measuringDevice.verificationComments")}`}
            name="verificationComments"
            data-cy="verificationComments"
          />
        </Col>
      </Row>
    </div>
  );
};

export default MeasuringDeviceVerificationFormStepOne;
