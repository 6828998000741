import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import "./SecretariatCorespondenceModal.scss";
import { show } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { useEffect } from "react";
import {
  EmailImageLoader,
  emailExtarctor,
} from "../../../../Utils/Emails/EmailsUtils";
import { useRef } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import {
  downloadFromBloob,
  saveFilesAsZip,
} from "../../../../Utils/Attachments/Attachments";
import {
  Card,
  Icon,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { t } from "i18next";
import SecretariatCorespondenceForm from "../../Forms/SecretariatCorespondenceForm/SecretariatCorespondenceForm";
import {
  SecretaryCorespondenceFormContext,
  useSecretaryCorespondenceFormContext,
} from "../../../../Contexts/SecretaryCorespondenceFormContext";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { edit } from "../../../../Utils/Permissions";
import { sanitize } from "dompurify";

const formName = "SecretariatCorespondenceForm";

const TabsArea = (props) => {
  const { steps, step, setStep } = useSecretaryCorespondenceFormContext();

  return (
    <Tabs
      className="TabsArea"
      value={step}
      onChange={(e, index) => setStep(index)}
    >
      {steps.map((item, index) => (
        <Tab data-cy={item.title} label={item.title} />
      ))}
    </Tabs>
  );
};

const EmailArea = (props) => {
  const emailAreaRef = useRef();
  const { content } = props;

  const loadData = () => {
    const iframe = emailAreaRef.current;
    const frameDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const emailArea = frameDocument.createElement("div");
    // emailArea.setHTML(content || "");
    emailArea.innerHTML = sanitize(content) || "";
    frameDocument.body.appendChild(emailArea);
    EmailImageLoader({ document: frameDocument });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [content]);

  return (
    <iframe title="EmailArea" ref={emailAreaRef} className="EmailArea"></iframe>
  );
};

const AttachmnetArea = (props) => {
  const {
    attachments,
    attachmentsList,
    number,
    update,
    subject,
    setAttachmentValues,
    attachmentValues,
  } = props;
  const refAttachmnetArea = useRef();

  const downloadAttachment = async (file) => {
    let data = null;
    if (!update) {
      const {
        payload: { data: _data },
      } = await GlobalAuthFetch({
        path: `/mail/downloadAttachment/${number}/${file}`,
        addons: { responseType: "blob" },
      });
      data = _data;
      if (Boolean(data)) {
        downloadFromBloob(data, file, {
          document: refAttachmnetArea.current,
        });
      }
      return;
    }

    const {
      payload: { data: _data },
    } = await GlobalAuthFetch({
      path: `/attachments/${file?.uuid}`,
      addons: { responseType: "blob" },
    });
    data = _data;
    if (Boolean(data)) {
      downloadFromBloob(data, file?.name, {
        document: refAttachmnetArea.current,
      });
    }
  };
  const downloadAllAttachments = async () => {
    const dataArray = [];

    if (attachmentValues?.length > 0) {
      for (const file of attachmentValues) {
        const attachmentObj = {
          data: file,
          name: file?.name,
        };
        dataArray.push(attachmentObj);
      }
    }

    if (!update) {
      const allAttachments = attachmentsList;

      for (let attachment of allAttachments) {
        const {
          payload: { data: _data },
        } = await GlobalAuthFetch({
          path: `/mail/downloadAttachment/${number}/${attachment}`,
          addons: { responseType: "blob" },
        });
        const attachmentObj = {
          data: _data,
          name: attachment,
        };
        dataArray.push(attachmentObj);
      }
      const fileName = `${t(
        "global:secreateryCorespondence.attachmentSaveAll"
      )}${subject || ""}`;
      await saveFilesAsZip(dataArray, fileName);
      return;
    }

    const allAttachments = attachments;

    for (let file of allAttachments) {
      const {
        payload: { data: _data },
      } = await GlobalAuthFetch({
        path: `/attachments/${file?.uuid}`,
        addons: { responseType: "blob" },
      });
      const attachmentObj = {
        data: _data,
        name: file?.name,
      };
      dataArray.push(attachmentObj);
    }
    const fileName = `${t("global:secreateryCorespondence.attachmentSaveAll")}${
      subject || ""
    }`;
    await saveFilesAsZip(dataArray, fileName);
    return;
  };

  const downloadAttachmentFromBrowser = (el) => {
    downloadFromBloob(el, el?.name);
  };

  const addNextAttachment = async (el) => {
    const {
      target: { files },
    } = el;
    setAttachmentValues((prevValues) => [...prevValues, ...files]);
  };

  return (
    <div className="AttachmentsArea" ref={refAttachmnetArea}>
      {attachmentValues?.map((el) => (
        <p
          className="AttachmentItem"
          onClick={() => downloadAttachmentFromBrowser(el)}
        >
          <Icon className="grey text">attach_file</Icon>
          <span className="text">{el?.name}</span>
        </p>
      ))}
      {attachmentsList?.map((el) => (
        <p className="AttachmentItem" onClick={() => downloadAttachment(el)}>
          <Icon className="grey text">attach_file</Icon>
          <span className="text">{el}</span>
        </p>
      ))}
      {attachments?.map((el) => (
        <p className="AttachmentItem" onClick={() => downloadAttachment(el)}>
          <Icon className="grey text">attach_file</Icon>
          <span className="text">{el?.name}</span>
        </p>
      ))}
      <div className="AttachmentButtons">
        <IconButton component="label">
          <input onChange={addNextAttachment} multiple hidden type="file" />
          <Icon
            className="grey text"
            style={{ fontSize: "18px", padding: "0" }}
          >
            attach_file
          </Icon>
        </IconButton>

        {(attachmentValues?.length > 0 ||
          attachmentsList?.length > 0 ||
          attachments?.length > 0) && (
          <IconButton onClick={downloadAllAttachments}>
            <Icon
              className="grey text"
              style={{ fontSize: "18px", padding: "0" }}
            >
              download
            </Icon>
          </IconButton>
        )}
      </div>
      <div className="AttachmentCounter">
        {t("global:secreateryCorespondence.attachmentCounter")}:{" "}
        {(attachmentsList?.length || attachments?.length || 0) +
          attachmentValues?.length || 0}
      </div>
    </div>
  );
};

const SecretariatCorespondenceModal = (props) => {
  const {
    handleHide,
    show: open,
    update,
    updateSocketSecretary,
    loadData,
    initialValues,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [contactValues, setContactValues] = useState({
    contact: initialValues?.contact,
    branch: initialValues?.branch,
    representative: initialValues?.representative,
  });
  const rights = [
    { name: "SECRETARY_READ" },
    { name: "SECRETARY_CREATE" },
    { name: "SECRETARY_UPDATE" },
    { name: "SECRETARY_DELETE" },
  ];

  const [attachmentValues, setAttachmentValues] = useState([]);
  let email = initialValues?.email;

  const [subject, setSubject] = useState(email?.subject);
  const [isSubmit, setIsSubmit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { title: t("global:secreateryCorespondence.corespondence"), error: false },
    { title: t("global:secreateryCorespondence.transfered"), error: false },
    { title: t("global:secreateryCorespondence.notes"), error: false },
  ]);

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };
  let fromEmail = email?.from ? emailExtarctor(email?.from) : "";

  if (Boolean(email?.from) && Boolean(contactValues?.contact?.name))
    fromEmail = fromEmail + " - ";
  if (Boolean(contactValues?.contact?.name))
    fromEmail = fromEmail?.concat("", contactValues?.contact?.name);
  if (Boolean(contactValues?.branch?.name))
    fromEmail = fromEmail?.concat(", ", contactValues?.branch?.name);
  if (Boolean(contactValues?.representative?.name))
    fromEmail = fromEmail?.concat(", ", contactValues?.representative?.name);
  if (Boolean(contactValues?.representative?.lastname))
    fromEmail = fromEmail?.concat(" ", contactValues?.representative?.lastname);

  const openContactModal = () => {
    dispatch(
      show("SecretariatContactModal", {
        setContactValues,
        contactValues,
        setSubject,
        subject,
      })
    );
  };

  const header = (
    <div className="SecretariatCorespondenceModalHeader">
      <Tooltip
        title={t("global:secreateryCorespondence.editContact")}
        placement="top"
      >
        <IconButton
          className="editButton"
          onClick={openContactModal}
          size="small"
        >
          <Icon style={{ fontSize: "0.938rem" }}>edit</Icon>
        </IconButton>
      </Tooltip>
      <div onClick={openContactModal} className="overlay"></div>
      <div className={`Header ${!contactError ? "" : "error text"}`}>
        <label className={!fromEmail ? "line-after" : ""}>
          <span className="header-text" onClick={openContactModal}>
            {t("global:secreateryCorespondence.fromReceiver")}:
          </span>
        </label>
        <label className={!subject ? "line-after" : ""}>
          <span className="header-text" onClick={openContactModal}>
            {t("global:secreateryCorespondence.topic")}:
          </span>
        </label>
      </div>
      <div className="Data">
        <label>{fromEmail}</label>
        <label>{subject}</label>
      </div>
    </div>
  );

  const buttons = (
    <>
      {edit(rights) && (
        <BMXButton
          disabled={disableButton}
          data-cy="submit"
          type="subbmit"
          bmxclass="Success"
          onClick={() => setIsSubmit(true)}
          form={formName}
        >
          {t("global:save")}
        </BMXButton>
      )}
    </>
  );

  const onClose = () => {
    if (Boolean(initialValues?.corespondenceNumber?.number)) return;

    const button = document.getElementById("numberButton");
    if (!update && Boolean(button)) {
      //Deleted non used number
      GlobalAuthFetch({
        path: "/correspondenceNumber/KP",
        method: "DELETE",
        data: button.innerText,
      });
    }
    handleHide();
  };

  return (
    <Modal
      className="SecretariatCorespondenceModal"
      open={open}
      onClose={onClose}
    >
      <Card>
        <div>
          <h4>{header}</h4>
          <CloseIcon className="CloseIcon" fontSize="small" onClick={onClose} />
        </div>
        <div className="SecretariatCorespondenceModalBody">
          <div className="FormArea">
            <SecretaryCorespondenceFormContext.Provider
              value={{
                isSubmit,
                steps,
                step,
                setStep,
                initialValues,
                setErrorHeader,
                formName,
                handleHide,
                update,
                updateSocketSecretary,
                contactValues,
                attachmentValues,
                subject,
                loadData,
                setDisableButton,
                setContactError,
              }}
            >
              <TabsArea />
              <div className="FormBodyArea">
                <SecretariatCorespondenceForm />
              </div>
            </SecretaryCorespondenceFormContext.Provider>
          </div>
          <EmailArea content={email?.content} />
          <AttachmnetArea
            attachmentValues={attachmentValues}
            setAttachmentValues={setAttachmentValues}
            update={update}
            number={email?.number}
            attachments={email?.attachments}
            attachmentsList={email?.attachmentsList}
            subject={email?.subject}
          />
        </div>
        <div className="ModalButton">{buttons}</div>
      </Card>
    </Modal>
  );
};

export default connectModal({ name: "SecretariatCorespondenceModal" })(
  SecretariatCorespondenceModal
);

SecretariatCorespondenceModal.propTypes = {
  header: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  handleHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  email: PropTypes.object.isRequired,
};

SecretariatCorespondenceModal.default = {
  email: {},
};
