import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const ContactsFormStepOne = (props) => {
  const { t } = useTranslation();
  // const [positionSelect, setPositinSelect] = useState([]);

  // useEffect(() => {
  // const fetchData = async () => {
  //   const {
  //     payload: {
  //       data: { dictionaryEntries },
  //     },
  //   } = await GlobalAuthFetch({ path: "/dictionary/position/fieldName" });
  //   setPositinSelect(dictionaryEntries);
  // };
  // fetchData();
  // }, []);

  return (
    <div>
      <BMXSeparator title={t("global:contacts.informationAboutContact")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.name")}*`}
            name="name"
            data-cy="name"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.shortName")}`}
            name="shortName"
            data-cy="shortName"
          />
        </Col>
        <Col xs={2}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.contactId")}`}
            name="contactId"
            data-cy="contactId"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={5}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.nip")}`}
            name="nip"
            data-cy="nip"
          />
        </Col>
      </Row>
      <BMXSeparator title={t("global:contacts.informationDetails")} />
      <Row>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.state")}`}
            name="state"
            data-cy="state"
          />
        </Col>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.city")}*`}
            name="city"
            data-cy="city"
          />
        </Col>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.postCode")}*`}
            name="postCode"
            data-cy="postCode"
          />
        </Col>
        <Col xs={3}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.post")}`}
            name="post"
            data-cy="post"
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5%" }}>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.street")}*`}
            name="street"
            data-cy="street"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.houseNumber")}*`}
            name="houseNumber"
            data-cy="houseNumber"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contacts.apartmentNumber")}`}
            name="apartmentNumber"
            data-cy="apartmentNumber"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContactsFormStepOne;
