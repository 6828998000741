import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import "./FormManagementFormStepOne.scss";
import BMXRadioButton from "../../../../../Components/Buttons/BMXRadioButton/BMXRadioButton";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import BMXAsynchronous from "../../../../../Components/Inputs/BMXAsynchronous/BMXAsynchronous";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import DetailsTable from "../../../Tables/DetailsTable/DetailsTable";
import { Tooltip, Icon, IconButton } from "@mui/material";
import { useForceUpdate } from "../../../../../Hooks/WindowHook";

const FormManagementFormStepOne = (props) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [representatives, setRepresentatives] = useState([]);
  const forceUpdate = useForceUpdate();

  const { values, form, showError } = props;

  const dispatch = useDispatch();

  const loadData = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const workerFetch = GlobalAuthFetch({ path: "/worker/all" });

    const [resContact, resWoreker] = await Promise.all([
      fetchContact,
      workerFetch,
    ]);
    setContacts(
      resContact?.payload?.data?.map((contact) => ({
        uuid: contact.uuid,
        label: contact.name,
        representatives: contact.representatives,
      }))
    );
    setWorkers(
      resWoreker?.payload?.data?.map((worker) => ({
        uuid: worker.uuid,
        label: `${worker.firstName} ${worker.lastName}`,
      }))
    );
  };

  useEffect(() => {
    if (Boolean(values?.contact)) {
      form.change(
        "contact",
        contacts?.find((el) => el.uuid === values?.contact?.uuid)
      );
    }
    // eslint-disable-next-line
  }, [contacts]);

  const loadRepresentative = (contact) => {
    const selectedRepresentatives = contact?.representatives?.map(
      (representative) => ({
        uuid: representative.uuid,
        label: `${representative.name || ""} ${representative.lastname || ""}`,
      })
    );
    if (!selectedRepresentatives) {
      setRepresentatives([]);
      form.change("representative", null);
      return;
    }
    setRepresentatives(selectedRepresentatives);
    form.change(
      "representative",
      selectedRepresentatives.find(
        (el) => el.uuid === values?.representative?.uuid
      ) || null
    );
  };

  useEffect(() => {
    loadRepresentative(values.contact);
    // eslint-disable-next-line
  }, [values.contact]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const [formOptions, setFormOptions] = useState([]);

  const getData = async (searchTerm) => {
    const data = GlobalAuthFetch({
      path: `/form/findByFormIdOrName/${searchTerm}`,
      disableError: true,
    });

    const [drawingListPayload] = await Promise.all([data]);
    const drawingList = drawingListPayload?.payload?.data?.map((p) => {
      return {
        uuid: p.uuid,
        value: `${p.formId || ""} ${p.name ? `- ${p.name}` : ""}`,
        ...p,
      };
    });
    if (!drawingList) return;
    setFormOptions(drawingList);
  };

  const onCloseFormSelect = () => {
    const formState = form.getState();
    const formSearchValue = formState?.values?.formSearch;
    if (!Boolean(formSearchValue) || typeof formSearchValue !== "object")
      return;
    const prevFormManagementElements = values?.formManagementElements;
    form.change("formManagementElements", [
      ...prevFormManagementElements,
      { ...formSearchValue, quantity: 1, comments: "" },
    ]);
  };

  const deleteFromArray = (idx) => {
    const formManagementElements = values?.formManagementElements;
    if (!formManagementElements) return;
    form.change(
      "formManagementElements",
      formManagementElements.map((el, index) =>
        index === idx ? { ...el, deleted: true } : el
      )
    );
  };

  return (
    <div
      className="FormManagementFormStepOne"
      style={{ justifyContent: "flex-start" }}
    >
      <Row className="MainRow">
        <Col xs={6}>
          <BMXSeparator title={t("global:formManagement.documentInfo")} />
          <Row>
            <Col xs={6}>
              <Field
                component={BMXRadioButton}
                options={[
                  { value: "Przyjęcie", name: "Przyjęcie" },
                  { value: "Wydanie", name: "Wydanie" },
                ]}
                name="documentType"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Field
                name="documentNumber"
                label={`${t("global:formManagement.documentNumber")}*`}
                component={BMXTextField}
                data-cy="documentNumber"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Field
                name="documentData"
                label={`${t("global:formManagement.documentData")}*`}
                component={BMXDatePicker}
                data-cy="documentData"
              />
            </Col>
            <Col xs={6}>
              <Field
                component={BMXAutocomplente}
                label={`${t("global:formManagement.worker.fullName")}`}
                name="worker"
                data-cy="worker"
                options={workers}
                currentValues={values}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Field
                component={BMXAutocomplente}
                label={`${t("global:formManagement.contact.name")}`}
                name="contact"
                data-cy="contact"
                options={contacts}
                currentValues={values}
                afterIcon="add"
                afterIconSize="13px"
                afterIconClick={() => {
                  const updateTableRegistry = () =>
                    updateDashboardByName("contact");
                  dispatch(
                    show("ContactsModal", {
                      updateTableRegistry,
                      header: t("global:contacts.add"),
                      loadData,
                    })
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Field
                component={BMXAutocomplente}
                label={`${t("global:formManagement.representative.fullName")}`}
                name="representative"
                data-cy="representative"
                options={representatives}
                disabled={!values.contact}
                currentValues={values}
                afterIcon="add"
                afterIconSize="13px"
                afterIconClick={() => {
                  const contactUuid =
                    values?.contact?.uuid || values?.contact?.value;
                  const updateSingleRegistry = () => {
                    updateDashboardByName("contact", contactUuid);
                  };
                  dispatch(
                    show("RepresentativesModal", {
                      loadData,
                      header: t("global:contact.representativeAdd"),
                      updateSingleRegistry,
                      uuid: contactUuid,
                    })
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BMXSeparator title={t("global:formManagement.addForm")} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={BMXAsynchronous}
                label={`${t("global:formManagement.formSearch")}`}
                name="formSearch"
                data-cy="formSearch"
                getData={getData}
                options={formOptions}
                onClose={onCloseFormSelect}
                onOpen={() => form.change("formSearch", null)}
                afterIcon="search"
                afterIconSize="19px"
                blurOnSelect={true}
              />
            </Col>
          </Row>
        </Col>
        <Col className="TableArea" xs={6}>
          <Row>
            <DetailsTable
              headers={[
                {
                  key: "name",
                  width: 50,
                  title: `${t("global:formManagement.name")}`,
                },
                {
                  key: "formId",
                  width: 40,
                  title: `${t("global:formManagement.formId")}`,
                },
                {
                  key: "technicalConditions",
                  width: 60,
                  title: `${t("global:formManagement.technicalConditions")}`,
                },
                {
                  key: "quantity",
                  width: 5,
                  title: `${t("global:formManagement.quantity")}`,
                },
                {
                  key: "comments",
                  width: 50,
                  title: `${t("global:formManagement.comments")}`,
                },
                {
                  key: "Buttons",
                  width: 5,
                  title: " ",
                },
              ]}
              tableData={values?.formManagementElements
                ?.filter((el) => !el.deleted)
                ?.map((el, index) => ({
                  ...el,
                  Buttons: [
                    <Tooltip title={t("global:formManagement.openForm")}>
                      <IconButton
                        onClick={() =>
                          dispatch(
                            show("formManagementElementModal", {
                              values: values,
                              selected: el,
                              form: form,
                              index: index,
                              forceUpdate: forceUpdate,
                            })
                          )
                        }
                      >
                        <Icon className="grey text">edit</Icon>
                      </IconButton>
                    </Tooltip>,
                    <Tooltip title={t("global:formManagement.delete")}>
                      <IconButton onClick={() => deleteFromArray(index)}>
                        <Icon className="error text">delete_outlined</Icon>
                      </IconButton>
                    </Tooltip>,
                  ],
                }))}
            />
          </Row>
          {showError ? (
            <Row>
              <Col className="mx-5 my-5 ">
                <h5 className="error text text-center">
                  {t("global:formManagement.error.missingData")}
                </h5>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default FormManagementFormStepOne;
