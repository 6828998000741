import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { isoReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  if (values?.externalEntity?.value)
    set(data, "externalEntity.uuid", values?.externalEntity?.value);
  if (values?.responsiblePerson?.value)
    set(data, "responsiblePerson.uuid", values?.responsiblePerson?.value);

  let payload;

  if (!update) {
    data.status = isoReducer?.availableStatuses.find((el) => !!el.isSelected);

    const res = await GlobalAuthFetch({
      path: `/iso`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/iso/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.title) {
    errorsStep0.title = t("global:iso.errors.title");
  }

  if (!values.description) {
    errorsStep0.description = t("global:iso.errors.description");
  }

  if (!values.externalEntity) {
    errorsStep0.externalEntity = t("global:iso.errors.externalEntity");
  }

  if (!values.responsiblePerson) {
    errorsStep0.responsiblePerson = t("global:iso.errors.responsiblePerson");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
