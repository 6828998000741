import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  registryRights: [],
  availableStatuses: null,
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      {
        key: "projectType.entryName",
        name: "global:drawing.projectType",
      },
      {
        key: "elementName",
        name: "global:drawing.elementName",
      },
      {
        key: "drawingNumber",
        name: "global:drawing.drawingNumber",
      },
      {
        key: "drawingId",
        name: "global:drawing.drawingId",
      },
      {
        key: "status.name",
        name: "global:drawing.status",
      },
    ],
    selectedSearchFields: ["projectType.entryName"],
    dictionaryFields: ["projectType"],
    url: "drawing",
  },
};

const drawingReducer = createSlice({
  name: "drawingReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = drawingReducer.actions;

export default drawingReducer.reducer;
