import i18n from "../../../../Utils/i18next/i18next";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const {updateReg} = props;
  updateReg({
    yearOfPurchase:values.yearOfPurchase
  })
  props.handleHide();
  return;
};

export const onValidate = (values, props) => {
  const errors = {};
  if(!values.yearOfPurchase){
    errors.yearOfPurchase=t("global:dateSearchYearOfPurchase")
  }
  if(!Number(values.yearOfPurchase)){
    errors.yearOfPurchase=t("global:dateSearchYearOfPurchaseNotNumber")
  }
  return errors;
};
