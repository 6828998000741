import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";

const MeasuringDeviceFormStepOne = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:measuringDevice.header")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:measuringDevice.equipmentName")}*`}
            name="equipmentName"
            data-cy="equipmentName"
          />
        </Col>

        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:measuringDevice.measurementRange")}*`}
            name="measurementRange"
            data-cy="measurementRange"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:measuringDevice.registrationNumber")}*`}
            name="registrationNumber"
            data-cy="registrationNumber"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:measuringDevice.serialNumber")}*`}
            name="serialNumber"
            data-cy="serialNumber"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:measuringDevice.checkTime")}*`}
            name="checkTime"
            data-cy="checkTime"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:measuringDevice.comments")}`}
            name="comments"
            data-cy="comments"
          />
        </Col>
      </Row>
    </div>
  );
};

export default MeasuringDeviceFormStepOne;
