import { withTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import warning from "./../../../../../src/assets/img/global/confirmModal/warning.svg";
import { compose } from "redux";
import React, { PureComponent } from "react";
import "./ConfirmWarningModal.scss";
import { Form } from "react-final-form";
import PropTypes from "prop-types";

class ConfirmWarningModal extends PureComponent {

  static defaultProps = {}

  render() {
    const { t, onSubmit, formName, mainText, contentText } = this.props;
    return (
      <Form onSubmit={onSubmit}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} className="ConfirmWarningModal" id={formName}>
            <Row>
              <Col className="d-flex justify-content-center" md={12}>
                <img className="img" src={warning} alt="Warning logo" />
              </Col>
            </Row>
            <Row>
              <Col className="title mt-3A" md={12}>
                {t(mainText)}
              </Col>
            </Row>
            <Row>
              <Col className="content" md={12}>
                {t(contentText)}
              </Col>
            </Row>
          </form>
        )}
      </Form>
    );
  }
}

ConfirmWarningModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  mainText: PropTypes.string,
  contentText: PropTypes.string
};

ConfirmWarningModal.defaultProps = {
  onSubmit: () => { },
  mainText: "global:confirmModal.delete",
  contentText:"global:confirmModal.confirmDeleteText"
};

export default compose(withTranslation())(ConfirmWarningModal);
