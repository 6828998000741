import { Field, Form } from "react-final-form";
import { onSubmit, onValidate } from "./PrintDocumentEmailFormSubmit";
import "./PrintDocumentEmailForm.scss";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BMXAutocomplente from "../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import { createFilterOptions } from "@mui/material";
import { useEffect, useState } from "react";

const PrintDocumentEmailForm = (props) => {
  const filter = createFilterOptions();

  const { t } = useTranslation();

  const {usersToSendEmail} = props

  const [emailsOptions, setEmailsOptions] = useState([]);

  useEffect(()=>{
    setEmailsOptions(usersToSendEmail?.map((el) => ({
      label: el.label,
      uuid: el.uuid,
    })));
  },[usersToSendEmail])

  return (
    <Form
      initialValues={{}}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => {
        const { form , values} = formProps;
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="PrintDocumentEmailForm"
          >
            <Row>
              <Col>
                <Field
                  component={BMXAutocomplente}
                  label={`${t("global:PrintDocumentEmailForm.emails")}`}
                  name="emails"
                  data-cy="emails"
                  options={emailsOptions}
                  currentValues={values}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    if (filtered?.length <= 0)
                      form.change("emails", inputValue);
                    return filtered;
                  }}
                />
              </Col>
            </Row>   
            <BMXButton bmxclass="Success" type="submit">{t("global:send")}</BMXButton>                     
          </form>
        );
      }}
    </Form>
  );
};

export default PrintDocumentEmailForm;
