import React, { useEffect, useState } from "react";
import "./ComplaintTo.scss";
import PrintDocumentTemplate from "../../../../Components/PrintDocumentTemplate/PrintDocumentTemplate";
import LaminLogo from "../Logo/LaminLogo.png";
import moment from "moment";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

export const ComplaintTo = () => {
  const [users, setUsers] = useState([]);

  const loadData = async () => {
    const res = await GlobalAuthFetch({ path: "/representative/all" });
    if (!res.payload) {
      return;
    }
    setUsers(
      res?.payload?.data?.map((user) => ({
        label: `${user?.name || ""} ${user?.lastname} ${user?.email}`,
        uuid: user?.email,
      }))
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const printData = JSON.parse(sessionStorage.getItem("printData"));

  const header = [
    { name: "PRZEDMIOT REKLAMACJI", index: "subjectOfTheComplaint" },
    { name: "ID PRZEDMIOTU REKLAMACJI", index: "idOfTheSubjectOfTheComplaint" },
    { name: "INDEX RYSUNKU", index: "drawingId" },
    { name: "ILOŚĆ", index: "numberOfComplaints" },
  ];
  return (
    <PrintDocumentTemplate
      fileName={`Reklamacja od ${printData?.contractor?.name || "dostawcy"}`}
      usersToSendEmail={users}
    >
      <div className="TargetPrintAreaComplaintTo">
        <div className="HeaderArea">
          <div className="LogoArea">
            <img className="LaminLogo" alt="LaminLogo" src={LaminLogo} />
            <span>Szelengowski Szwarc Sp. Jawna</span>
            <span>Wierzchucin Królewski 77,</span>
            <span>86-017 Wierzchucin Królewski</span>
          </div>
          <span className="DateArea">
            Wierzchucin Królewski dnia{" "}
            {moment(printData?.dateOfCompletionOfTheComplaint).format(
              "DD-MM-YYYYr"
            )}
          </span>
        </div>
        <div className="HeaderEnd">
          {" "}
          <span className="InnerText">REKLAMACJA OD DOSTAWCY</span>
        </div>
        <div className="ComplaintInfoArea">
          <div className="ReportingComplaints">
            <h6> ZGŁASZAJĄCY REKLAMACJE: </h6>
            <span>{printData?.contractor?.name || ""}</span>
            <span>
              ul. {printData?.contractor?.street || ""}{" "}
              {printData?.contractor?.houseNumber || ""}{" "}
              {printData?.contractor?.postCode || ""}{" "}
              {printData?.contractor?.post || ""}
            </span>
            <span>NIP: {printData?.contractor?.nip || ""}</span>
            <span>
              {printData?.representative?.name || ""}{" "}
              {printData?.representative?.lastname || ""}{" "}
            </span>
            <span>tel: {printData?.representative?.phoneNumber || ""} </span>
            <span>e-mail: {printData?.representative?.email || ""}</span>
          </div>
          <div className="RecipientOfTheComplaint">
            <h6> ODBIORCA REKLAMACJI: </h6>
            <span>
              {printData?.responsibleAt?.firstName || ""}{" "}
              {printData?.responsibleAt?.lastName || ""}
            </span>
            <span>Szelengowski Szwarc Sp. Jawna</span>
            <span>Wierzchucin Królewski 77,</span>
            <span>86-017 Wierzchucin Królewski</span>
          </div>
        </div>
        <h4 className="mt-5 text-center">
          Protokół przekazania nr: {printData?.index || ""}
        </h4>
        <h5 className="mt-2 text-center">
          do reklamacji nr: {printData?.complaintNumber || ""}
        </h5>

        <div className="mt-5 ComplaintList">
          {header.map((el) => (
            <div className="ComplaintItem">
              <div className="ComplaintHeader">{el.name}</div>
              <div className="ComplaintData">{printData[el.index]}</div>
            </div>
          ))}
        </div>
        <div className="SginArea">
          <div className="Sign1">
            <span>Dokument wystawił:</span>
            <span>
              ........................................................
            </span>
          </div>
          <div className="Sign2">
            <span>Odebrał:</span>
            <span>
              ........................................................
            </span>
          </div>
        </div>
        <span className="Footer">www.lamin.pl</span>
      </div>
    </PrintDocumentTemplate>
  );
};
