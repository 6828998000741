import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import BMXCardModal from "../../../../Components/Modals/BMXCardModal/BMXCardModal";

import FixedAssetsYearForm from "../../Forms/FixedAssetsYearForm/FixedAssetsYearForm";

const FixedAssetsYearModal = (props) => {
  const formName = "fixedAssetsYearForm";
  const { handleHide, show } = props;
  const { t } = useTranslation();
  return (
    <BMXCardModal
      left="350"
      bottom="30px"
      width="350px"
      height="180px"
      header={t("global:fixedAssets.year")}
      open={show}
      onClose={handleHide}
      onOutside={true}
    >
      <FixedAssetsYearForm {...props} formName={formName} />
      <div className="ButtonAreaResetPassword">
        <BMXButton bmxclass={"Success "} form={formName} type="Submit">
          {t("global:fixedAssetsYear.send")}
        </BMXButton>
      </div>
    </BMXCardModal>
  );
};

export default connectModal({ name: "fixedAssetsYearModal" })(
  FixedAssetsYearModal
);
