import { Field, Form } from "react-final-form";
import { onSubmit, onValidate } from "./FormManagementElementFormSubmit";
import "./FormManagementElementForm.scss";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../Components/Separator/BMXSeparator";

const FormManagementElementForm = (props) => {
  const { t } = useTranslation();
  const { selected} = props;
  return (
    <Form
      initialValues={selected}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="FormManagementElementForm"
          >
            <Row>
              <Col>
                <BMXSeparator />
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <Field
                  name="quantity"
                  label={`${t("global:formManagement.quantity")}*`}
                  component={BMXTextField}
                  type="number"
                  data-cy="quantity"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="technicalConditions"
                  label={`${t("global:formManagement.technicalConditions")}*`}
                  component={BMXTextField}
                  data-cy="technicalConditions"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="comments"
                  label={`${t("global:formManagement.comments")}`}
                  component={BMXTextField}
                  data-cy="comments"
                  multiline={true}
                  maxRows={3}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <div
                  style={{
                    position: "absolute",
                    bottom: "1.5rem",
                    width: "100%",
                  }}
                >
                  <BMXSeparator />
                </div>
              </Col>
            </Row>

            <BMXButton bmxclass="Success" type="submit">
              {t("global:save")}
            </BMXButton>
          </form>
        );
      }}
    </Form>
  );
};

export default FormManagementElementForm;
