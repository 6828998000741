import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import { fromToTypes } from "../../../../../Utils/Complaint/ComplaintUtils";

const ComplaintFormStepOne = (props) => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [detectedInTimeDictionary, setDetectedInTimeDictionary] = useState([]);
  const [complaintTypeDictionary, setComplaintTypeDictionary] = useState([]);
  const typesFromTo = fromToTypes(t);

  const dispatch = useDispatch();
  const { values } = props;

  const loadData = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const complaintTypeFetch = GlobalAuthFetch({
      path: "/dictionary/complaintType/fieldName",
    });
    const typeOfDetectedInTimeFetch = GlobalAuthFetch({
      path: "/dictionary/detectedInTime/fieldName",
    });
    const workerFetch = GlobalAuthFetch({ path: "/worker/all" });

    const [resComplaintType, typeOfDetectedInTime, resContact, resWoreker] =
      await Promise.all([
        complaintTypeFetch,
        typeOfDetectedInTimeFetch,
        fetchContact,
        workerFetch,
      ]);
    setComplaintTypeDictionary(
      resComplaintType?.payload?.data?.dictionaryEntries
    );
    setDetectedInTimeDictionary(
      typeOfDetectedInTime?.payload?.data?.dictionaryEntries
    );
    setContacts(resContact?.payload?.data);
    setWorkers(resWoreker?.payload?.data);
  };

  useEffect(() => {    
    loadData();
    // eslint-disable-next-line
  }, []);

  const getSelectedContraktor = (contractor) => {
    if (!contractor) return null;
    return contacts.find(
      (el) => el.uuid === contractor.value || el.uuid === contractor.uuid
    );
  };

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:complaint.newComplaint")} />
      <Row>
        <Col xs={8}>
          <Field
            data-cy="contractor"
            style={{ width: "92.57%" }}
            component={BMXSelectField}
            values={contacts?.map((el) => ({
              value: el.uuid,
              name: `${el.name}`,
            }))}
            label={`${t("global:complaint.contractor.name")}*`}
            name="contractor"
            afterIcon="add"
            afterIconSize="13px"
            afterIconClick={() => {
              const updateTableRegistry = () =>
                updateDashboardByName("contact");
              dispatch(
                show("ContactsModal", {
                  updateTableRegistry,
                  header: t("global:contacts.add"),
                  loadData,
                })
              );
            }}
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXSelectField}
            values={workers?.map((el) => ({
              name: `${el.firstName || " "} ${el.lastName || " "}`,
              value: el.uuid,
            }))}
            label={`${t("global:complaint.responsibleAt.details")}*`}
            name="responsibleAt"
            data-cy="responsibleAt"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={5}>
          <Field
            data-cy="representative"
            beforeIcon="people"
            afterIcon="add"
            afterIconSize="13px"
            afterIconClick={() => {
              const contactUuid =
                values?.contractor?.uuid || values?.contractor?.value;
              const updateSingleRegistry = () =>
                updateDashboardByName("contact", contactUuid);
              dispatch(
                show("RepresentativesModal", {
                  loadData,
                  header: t("global:contact.representativeAdd"),
                  updateSingleRegistry,
                  uuid: contactUuid,
                })
              );
            }}
            disabled={!values?.contractor}
            component={BMXSelectField}
            values={getSelectedContraktor(
              values?.contractor
            )?.representatives?.map((el) => ({
              value: el.uuid,
              name: `${el.name || ""} ${el.lastname || ""}`,
            }))}
            label={`${t("global:complaint.representative.details")}*`}
            name="representative"
            renderValue={(el) => {
              return `${el.name || ""} ${el.lastname || ""}`;
            }}
          />
        </Col>
        <Col xs={{ span: 4, offset: 3 }}>
          <Field
            name="complaintNumber"
            label={`${t("global:complaint.complaintNumber.details")}*`}
            component={BMXTextField}
            data-cy="complaintNumber"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Field
            name="subjectOfTheComplaint"
            label={`${t("global:complaint.subjectOfTheComplaint")}*`}
            component={BMXTextField}
            data-cy="subjectOfTheComplaint"
          />
        </Col>
        <Col xs={4}>
          <Field
            name="drawingId"
            label={`${t("global:complaint.drawingId")}*`}
            component={BMXTextField}
            data-cy="drawingId"
          />
        </Col>
        <Col xs={4}>
          <Field
            name="fromTo"
            label={`${t("global:complaint.fromTo")}*`}
            component={BMXSelectField}
            values={[
              typesFromTo.from,
              typesFromTo.to
            ]
            }
            data-cy="fromTo"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Row>
            <Col xs={4}>
              <Field
                name="idOfTheSubjectOfTheComplaint"
                label={`${t("global:complaint.idOfTheSubjectOfTheComplaint")}*`}
                component={BMXTextField}
                data-cy="idOfTheSubjectOfTheComplaint"
              />
            </Col>
            <Col xs={4}>
              <Field
                name="numberOfComplaints"
                label={`${t("global:complaint.complaintNumber.details")}*`}
                component={BMXTextField}
                data-cy="numberOfComplaints"
              />
            </Col>
            <Col xs={4}>
              <Field
                name="lotNumber"
                label={`${t("global:complaint.lotNumber")}*`}
                component={BMXTextField}
                data-cy="lotNumber"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Field
            name="dateOfComplaints"
            label={`${t("global:complaint.dateOfComplaints.deiatls")}*`}
            component={BMXDatePicker}
            data-cy="dateOfComplaints"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 8 }}>
          <Row>
            <Col xs={4}>
              <Field
                name="complaintType"
                values={complaintTypeDictionary?.map((el) => ({
                  name: el.entryName,
                  value: el.uuid,
                }))}
                component={BMXSelectField}
                label={`${t("global:complaint.complaintType")}*`}
                data-cy="complaintType"
              />
            </Col>
            <Col xs={4}>
              <Field
                name="detectedInTime"
                values={detectedInTimeDictionary?.map((el) => ({
                  name: el.entryName,
                  value: el.uuid,
                }))}
                component={BMXSelectField}
                label={`${t("global:complaint.detectedInTime")}`}
                data-cy="detectedInTime"
              />
            </Col>
            <Col xs={4}>
              <Field
                name="invoiceNumber"
                label={`${t("global:complaint.invoiceNumber")}`}
                component={BMXTextField}
                data-cy="invoiceNumber"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Field
            name="descriptionOfNonCompliance"
            label={`${t("global:complaint.descriptionOfNonCompliance")}*`}
            component={BMXTextField}
            data-cy="descriptionOfNonCompliance"
            multiline={true}
            maxRows={5}
          />
        </Col>
        <Col xs={4}>
          <Field
            className="mt-4"
            name="dateOfCompletionOfTheComplaint"
            label={`${t("global:complaint.dateOfCompletionOfTheComplaint")}*`}
            component={BMXDatePicker}
            data-cy="dateOfCompletionOfTheComplaint"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ComplaintFormStepOne;
