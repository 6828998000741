import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { submit, validate } from "./SecretariatCorespondenceContactFormSubmit";
import { Col, Row } from "react-bootstrap";
import "./SecretariatCorespondenceContactForm.scss";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { useEffect } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { updateDashboardByName } from "../../../../Utils/Sockets";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import BMXTextField from "../../../../Components/Inputs/BMXTextField/BMXTextField";

const SecretariatCorespondenceContactForm = (props) => {
  const { contactValues: initialValues, formName, subject } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const REGISTRY_NAME = "contact";

  const loadData = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);
    setContacts(resContact?.payload?.data);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const getSelectedContact = (contact) => {
    if (!contact) return null;
    return contacts.find(
      (el) => el.uuid === contact.value || el.uuid === contact.uuid
    );
  };

  return (
    <Form
      initialValues={{ ...initialValues, subject: subject }}
      onSubmit={(val) => submit(val, { ...props, contacts: contacts })}
      validate={validate}
    >
      {({ handleSubmit, values }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="SecretariatCorespondenceContactForm justify-content-around"
            id={formName}
          >
            <Row>
              <Col xs={10}>
                <Field
                  data-cy="transferToPerson"
                  style={{ width: "92.57%" }}
                  component={BMXSelectField}
                  values={contacts.map((el) => ({
                    value: el.uuid,
                    name: `${el.name}`,
                  }))}
                  label={`${t(
                    "global:secretariatCorespondenceContact.contact"
                  )}`}
                  name="contact"
                  afterIcon="add"
                  afterIconSize="13px"
                  afterIconClick={() => {
                    const updateTableRegistry = () =>
                      updateDashboardByName(REGISTRY_NAME);
                    dispatch(
                      show("ContactsModal", {
                        updateTableRegistry,
                        header: t("global:contacts.add"),
                        loadData,
                      })
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={10}>
                <Field
                  data-cy="branch"
                  beforeIcon="domain"
                  afterIcon="add"
                  afterIconSize="13px"
                  afterIconClick={() => {
                    const contactUuid =
                      values?.contact?.uuid || values?.contact?.value;
                    const updateSingleRegistry = () =>
                      updateDashboardByName(REGISTRY_NAME, contactUuid);
                    dispatch(
                      show("BranchesModal", {
                        loadData,
                        header: t("global:contact.branchAdd"),
                        updateSingleRegistry,
                        uuid: contactUuid,
                      })
                    );
                  }}
                  disabled={!values?.contact}
                  component={BMXSelectField}
                  values={getSelectedContact(values?.contact)?.branches?.map(
                    (el) => ({ value: el.uuid, name: `${el.name || ""}` })
                  )}
                  label={`${t(
                    "global:secretariatCorespondenceContact.branch"
                  )}`}
                  name="branch"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={10}>
                <Field
                  data-cy="representative"
                  beforeIcon="people"
                  afterIcon="add"
                  afterIconSize="13px"
                  afterIconClick={() => {
                    const contactUuid =
                      values?.contact?.uuid || values?.contact?.value;
                    const updateSingleRegistry = () =>
                      updateDashboardByName(REGISTRY_NAME, contactUuid);
                    dispatch(
                      show("RepresentativesModal", {
                        loadData,
                        header: t("global:contact.representativeAdd"),
                        updateSingleRegistry,
                        uuid: contactUuid,
                      })
                    );
                  }}
                  disabled={!values?.contact}
                  component={BMXSelectField}
                  values={getSelectedContact(
                    values?.contact
                  )?.representatives?.map((el) => ({
                    value: el.uuid,
                    name: `${el.name || ""} ${el.lastname || ""}`,
                  }))}
                  label={`${t(
                    "global:secretariatCorespondenceContact.representative"
                  )}`}
                  name="representative"
                  renderValue={(el) => {
                    return `${el.name || ""} ${el.lastname || ""}`;
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Field
                  data-cy="subject"
                  component={BMXTextField}
                  label={`${t("global:secreateryCorespondence.subjectv2")}`}
                  name="subject"
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Form>
  );
};

export default SecretariatCorespondenceContactForm;
