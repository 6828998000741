import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { loadPictures } from "../../../../../Utils/Workers/WorkersUtils";

import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import BMXUserView from "./../../../../../Components/BMXUserView/BMXUserView";
import "./StorageToUserFormStepOne.scss";
const StorageToUserStepOne = (props) => {
  const { t } = useTranslation();
  const { values } = props;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const {
        payload: { data },
      } = await GlobalAuthFetch({ path: "/worker/all" });
      const workers = data.sort((a, b) => a.lastName.localeCompare(b.lastName));
      const newUsers = [];
      for (const el of workers) {
        newUsers.push({
          uuid: el.uuid,
          lastName: el.lastName,
          firstName: el.firstName,
          profileImage: await loadPictures(el),
        });
      }

      setUsers(newUsers.map(user => ({
        label: `${user.lastName} ${user.firstName}`,
        ...user
      })));
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="StorageToUserStepOne">
      <BMXSeparator title={t("global:storage.sendToUser")} />
      <Row>
        <Col md={12}>
          <Field
            component={BMXAutocomplente}
            options={users}
            currentValues={values}
            label={`${t("global:storage.selectRecipient")}*`}
            limitTags={1}
            multiple
            name="toUser"
            renderOption={(props, option) => (
              <li key={option.uuid} {...props}>
                <BMXUserView color="primary" user={option}></BMXUserView>
              </li>
            )}
            renderTags={(values) => {
              return values.map((option, index) => (
                <BMXUserView
                  comma={values.length - 1 !== index}
                  key={option.uuid}
                  color="primary"
                  user={option}
                ></BMXUserView>
              ));
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StorageToUserStepOne;
