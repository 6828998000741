import { t } from "i18next";
import { cloneDeep } from "lodash";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { FetchMethodType } from "../../../../Utils/GlobalVariables/Fetch/MethodsType";
import { NotificationManager } from "react-notifications";

export const onSubmit = async (values, props) => {
  const data = cloneDeep(values);

  if (typeof data.emails === "object") data.emails = data.emails.uuid;

  const { emails } = data;

  const formData = new FormData();

  formData.set("emails", emails);
  formData.set("document", props.document);

  const { payload } = await GlobalAuthFetch({
    path: "/mail/documentSend",
    method: FetchMethodType.post,
    data: formData,
    addons: {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    },
  });

  if (payload?.status > 200 || !payload?.status) {
    return;
  }
  props.handleHide();
  NotificationManager.success(t("global:success"));
};

export const onValidate = (values, props) => {
  const errors = {};
  if (!values.emails) {
    errors.emails = t("global:PrintDocument.errors.emails");
  }

  return errors;
};
