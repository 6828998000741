import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { FetchMethodType } from "../../../../../Utils/GlobalVariables/Fetch/MethodsType";

const IsoFormStepOne = (props) => {
  const { t } = useTranslation();
  const [workers, setWorkers] = useState([]);

  // NEW COMPONENET
  const [contacts, setContacts] = useState([]);
  const loadContacts = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);
    setContacts(resContact?.payload?.data);
  };
  const loadWorkers = async () => {
    const { payload } = await GlobalAuthFetch({
      path: "/worker/all",
      method: FetchMethodType.get,
    });
    if (payload) {
      setWorkers(payload.data);
    }
  };

  useEffect(() => {
    loadContacts();
    loadWorkers();

    // LOADING DICTIONARIS
    const fetchData = async () => {
      // IMPORT DICTIONARIES IF IT NECESSARY
    };
    fetchData();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:iso.basicInformation")} />
      <Row>
        <Col xs={8}>
          <Field
            component={BMXTextField}
            label={`${t("global:iso.title")}*`}
            name="title"
            data-cy="title"
          />
        </Col>

        <Col xs={4}>
          <Field
            data-cy="responsiblePerson"
            component={BMXSelectField}
            values={workers.map((e) => ({
              value: e.uuid,
              name: `${e.firstName} ${e.lastName}`,
            }))}
            label={`${t("global:iso.responsiblePerson")}`}
            name="responsiblePerson"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={8}>
          <Field
            component={BMXTextField}
            label={`${t("global:iso.description")}*`}
            name="description"
            data-cy="description"
          />
        </Col>
        <Col xs={4}>
          <Field
            data-cy="externalEntity"
            component={BMXSelectField}
            values={contacts?.map((el) => el)}
            label={`${t("global:iso.externalEntity")}*`}
            name="externalEntity"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={8}>
          <Field
            multiline
            maxRows={4}
            component={BMXTextField}
            label={`${t("global:iso.listOfForms")}`}
            name="listOfForms"
            data-cy="listOfForms"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:iso.comments")}`}
            name="comments"
            data-cy="comments"
          />
        </Col>
      </Row>
    </div>
  );
};

export default IsoFormStepOne;
