import { withTranslation } from "react-i18next";
import "./AlertFormStepTwo.scss";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import BMXButton from "./../../../../../Components/Buttons/BMXButton/BMXButton";
import { Col, Row } from "react-bootstrap";
import { compose } from "redux";
import React, { PureComponent } from "react";
import BMXUserView from "./../../../../../Components/BMXUserView/BMXUserView";
import { Icon } from "@mui/material";
import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import { Field } from "react-final-form";
import { isEqual } from "lodash";
import { loadPictures } from "../../../../../Utils/Workers/WorkersUtils";
import { connect } from "react-redux";

class AlertFormStepTwo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      workers: [],
      departments: [],
    };
  }

  filterSelected = () => {
    const { values } = this.props;
    const { workers } = this.state;

    return workers.filter((el) =>
      values?.alertFor.map((elm) => elm.uuid).includes(el.uuid)
    );
  };

  loadData = async (prevProps) => {
    const { workers, departments } = this.props;

    if (!isEqual(workers, prevProps.workers)) {
      const newWorkers = [];
      for (const el of workers) {
        newWorkers.push({
          uuid: el.uuid,
          lastName: el.lastName,
          firstName: el.firstName,
          profileImage: await loadPictures(el),
        });
      }

      this.setState(() => ({
        workers: newWorkers,
      }));
    }

    if (!isEqual(departments, prevProps.departments)) {
      const newDepartments = [];
      for (const el of departments) {
        newDepartments.push({
          uuid: el.uuid,
          name: el.name,
          shortName: el.shortName,
        });
      }

      this.setState(() => ({
        departments: newDepartments,
      }));
    }
  };

  componentDidUpdate(prevProps) {
    this.loadData(prevProps);
  }

  forMe = () => {
    const {
      values,
      user: { uuid },
    } = this.props;
    const { workers } = this.state;

    values.alertFor = workers.filter((el) => el.uuid === uuid);
    this.forceUpdate();
  };

  forEveryone = () => {
    const { values } = this.props;
    const { workers } = this.state;

    values.alertFor = workers;
    this.forceUpdate();
  };

  render() {
    const { t, values } = this.props;
    const { workers, departments } = this.state;

    return (
      <>
        <div className="AlertFormStepTwo">
          <Row>
            <Col>
              <BMXSeparator
                title={t("global:alert.assignPersonsAndDepartments")}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col md={3}>
              <BMXButton
                data-cy="alert.forMe"
                className=""
                onClick={this.forMe}
              >
                <Icon className="mx-1" fontSize={"medium"}>
                  person
                </Icon>
                {t("global:alert.forMe")}
              </BMXButton>
            </Col>
            <Col md={3}>
              <BMXButton
                data-cy="alert.forEveryone"
                className=""
                onClick={this.forEveryone}
              >
                <Icon className="mx-1" fontSize={"medium"}>
                  people
                </Icon>
                {t("global:alert.forEveryone")}
              </BMXButton>
            </Col>
            <Row className="mt-5">
              <Col md={6}>
                <Field
                  component={BMXAutocomplente}
                  options={workers}
                  data-cy="alert.alertFor"
                  currentValues={values}
                  label={t("global:alert.alertFor")}
                  limitTags={1}
                  multiple
                  name="alertFor"
                  renderOption={(props, option) => (
                    <li key={option.uuid} {...props}>
                      <BMXUserView color="primary" user={option}></BMXUserView>
                    </li>
                  )}
                  getOptionLabel={(option) =>
                    String(
                      `${option.lastName} ${option.firstName} ${option.firstName} ${option.lastName}`
                    )
                  }
                  renderTags={(values) => {
                    return values.map((option, index) => (
                      <BMXUserView
                        comma={values.length - 1 !== index}
                        key={option.uuid}
                        color="primary"
                        user={option}
                      ></BMXUserView>
                    ));
                  }}
                />
              </Col>
              <Col md={6}>
                <Field
                  component={BMXAutocomplente}
                  data-cy="alert.alertForDepartments"
                  options={departments}
                  currentValues={values}
                  label={`${t("global:alert.alertForDepartments")}`}
                  limitTags={1}
                  multiple
                  getOptionLabel={(option) => String(`${option.name}`)}
                  renderOption={(props, option) => (
                    <li key={option.uuid} {...props}>
                      <BMXUserView
                        color="primary"
                        department={option}
                      ></BMXUserView>
                    </li>
                  )}
                  renderTags={(values) => {
                    return values.map((option, index) => (
                      <BMXUserView
                        comma={values.length - 1 !== index}
                        key={option.uuid}
                        color="primary"
                        department={option}
                      ></BMXUserView>
                    ));
                  }}
                  name="alertForDepartments"
                />
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              <BMXSeparator
                miniSeparator={true}
                title={t("global:alert.alertFor")}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="summary">
                <span className="pb-2 d-block">{t("global:users")}</span>
                <div className="area">
                  {values?.alertFor?.map((el) => (
                    <BMXUserView
                      className="mt-2"
                      line={"line"}
                      color="primary"
                      user={el}
                    ></BMXUserView>
                  ))}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="summary">
                <span className="pb-2 d-block">
                  {t("global:departments.header")}
                </span>
                <div className="area">
                  {values?.alertForDepartments?.map((el) => (
                    <BMXUserView
                      className="mt-2"
                      line={"line"}
                      color="secondary"
                      department={el}
                    ></BMXUserView>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(
  connect((state) => ({
    user: state.userSession.user,
  })),
  withTranslation()
)(AlertFormStepTwo);
