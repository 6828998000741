import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  registryRights: [],
  table: {
    search: "",
    filterSearch: "",
    dateSearchField:"",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "index", name: "global:formManagementReducer.index" },
      { key: "documentNumber", name: "global:formManagementReducer.documentNumber" },
      { key: "documentType", name: "global:formManagementReducer.documentType" },
      { key: "contact.name", name: "global:formManagementReducer.contact.name" },
      { key: "worker.firstName, worker.lastName", name: "global:formManagementReducer.worker" },
      { key: "representative.name, representative.lastname", name: "global:formManagementReducer.representative.nameLastName" },
      { key: "status.name", name: "global:formManagementReducer.status" },
    ],
    selectedSearchFields: ["index"],
    dictionaryFields: [],
    url: "formmanagement",
  },
};

const formManagementReducer = createSlice({
  name: "formManagementReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = formManagementReducer.actions;

export default formManagementReducer.reducer;
