export const makeChunkedArray = (arr, chunkedSize) => {
    const tmp = [];
    if(!arr)
        return tmp;
    for( let i = 0; i < arr.length; i+=chunkedSize){

        const chunk = arr.slice(i,i+chunkedSize);

        tmp.push(chunk);

    }

    return tmp;
}
