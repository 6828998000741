import { Button, Icon } from "@mui/material";
import PropTypes from 'prop-types';
import "./RolesItem.scss";
const RolesItem = (props) => {
  const {
    children: { icon, name, rights },
    values,
    setValues,
  } = props;

  rights.sort((a, b) => {
    if (a.name < b.name)
      return -1;
    if (a.name > b.name)
      return 1;
    return 0;
  })
  
  const onClick = (type) => {
    if (values?.map(el => el?.uuid).indexOf(type?.uuid) < 0) {
      setValues([...values, type]);
      return;
    }
    setValues(values?.filter((el) => el?.uuid !== type?.uuid));
  };

  const displayRights = [...rights?.filter(el=>!el.name?.includes('HIDE')), rights?.find(el=>el?.name?.includes("HIDE"))];

  return (
    <div className="RolesItem">
      <Icon>{icon}</Icon>
      <div className="mx-2">
        <span>{name}</span>
        <div className="d-flex my-1">
          {displayRights?.map((rig) => (
            <Button
              key={rig?.uuid}
              className={`Buttons ${values?.map(el => el?.uuid).includes(rig?.uuid) ? "selected" : ""
                }`}
              size="small"
              onClick={() => onClick(rig)}
              variant="contained"
            >
              {rig?.name?.split("_")[1]}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};


RolesItem.propTypes = {    
  /**
    Props zawiera nazwe pliku wraz z jego rozmiarem
  */ 
  className: PropTypes.node,    
  /**
    Props zawiera nazwe pliku wraz z jego rozmiarem
  */ 
    label: PropTypes.node,    
  /**
    Props zawiera nazwe pliku wraz z jego rozmiarem
  */ 
    input: PropTypes.node,    
};
export default RolesItem;

