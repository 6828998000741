import React, { Suspense, lazy, Component } from "react";
import "./App.scss";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "react-notifications/lib/notifications.css";
import AuthRoute from "../Utils/Auth/AuthRoute";
import BMXLoader from "../Components/Loader/BMXLoader/BMXLoader";
import Modal from "./Components/Modals/Modal/Modal";
import ResetPasswordModal from "./Components/Modals/ResetPasswordModal/ResetPasswordModal";
import FixedAssetsYearModal from "./Components/Modals/FixedAssetsYearModal/FixedAssetsYearModal";
import Cookies from "js-cookie";
import { setUser } from "../Redux/Reducers/UserSession";
import { AuthFetch, setAuth } from "../Redux/Reducers/AuthFetchReducer";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

// PERSONAL
import ChangeProfilePasswordModal from "./Components/Modals/ChangeProfilePasswordModal/ChangeProfilePasswordModal";
import ChangeImageModal from "./Components/Modals/ChangeImageModal/ChangeImageModal";
import StorageAddFile from "./Components/Modals/StorageAddFile/StorageAddFile";
import StorageEditFile from "./Components/Modals/StorageEditFile/StorageEditFile";
import StorageToUser from "./Components/Modals/StorageToUser/StorageToUser";

// ADMIN
import RegistryModal from "./Components/Modals/RegistryModal/RegistryModal";
import StatusModal from "./Components/Modals/StatusModal/StatusModal";
import SettingsModal from "./Components/Modals/SettingsModal/SettingsModal";
import TranslationModal from "./Components/Modals/TranslationModal/TranslationModal";

// DETAILS TABS
import AttachmentsModal from "./Components/Modals/AttachmentsModal/AttachmentsModal";
import AttachmentsFolderModal from "./Components/Modals/AttachmentsFolderModal/AttachmentsFolderModal";
import AttachmentsFromStorageModal from "./Components/Modals/AttachmentsStorageModal/AttachmentsStorageModal";
import FolderModal from "./Components/Modals/FolderModal/FolderModal";
import AlertModal from "./Components/Modals/AlertModal/AlertModal";
import NoteModal from "./Components/Modals/NoteModal/NoteModal";

import AttachmentsFromTemplateForm from "./Components/Modals/AttachmentsFromTemplateModal/AttachmentsFromTemplateModal";
import AttachmentsEditFileModal from "./Components/Modals/AttachmentsEditFileModal/AttachmentsEditFileModal";
import BMXConfirmModal from "./../PC/Components/ConfirmModals/ConfirmModals";
import { connectSocket } from "../Redux/Reducers/SocketReducer";
import DictionaryModal from "./Components/Modals/DictionaryModal/DictionaryModal";
import DictionaryValueModal from "./Components/Modals/DictionaryValueModal/DictionaryValueModal";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { isEqual } from "lodash";
import i18n from "../Utils/i18next/i18next";
import { removeError } from "../Redux/Reducers/ErrorReducer";
import DictionaryConnectModal from "./Components/Modals/DictionaryConnectModal/DictionaryConnectModal";
import RolesModal from "./Components/Modals/RolesModal/RolesModal";
//REGISTRY
import WorkerModal from "./Components/Modals/WorkerModal/WorkerModal";
import WorkerStaffModal from "./Components/Modals/WorkerStaffModal/WorkerStaffModal";
import GlobalAlertModal from "./Components/Modals/GlobalAlertModal/GlobalAlertModal";
import DepartmentModal from "./Components/Modals/DepartmentModal/DepartmentModal";
import ContactsModal from "./Components/Modals/ContactsModal/ContactsModal";
import RepresentativesModal from "./Components/Modals/RepresentantivesModal/RepresentantivesModal";
import BranchesModal from "./Components/Modals/BranchesModal/BranchesModal";
import FullScreenDialog from "./Components/Modals/FullScreenDialog/FullScreenDialog";
import SecretariatCorespondenceModal from "./Components/Modals/SecretariatCorespondenceModal/SecretariatCorespondenceModal";
import SecretariatCorespondenceModalSended from "./Components/Modals/SecretariatCorespondenceModalSended/SecretariatCorespondenceModalSended";
import SettingsSecretaryModal from "./Components/Modals/SettingsSecretaryModal/SettingsSecretaryModal";
import SecretariatTransferModal from "./Components/Modals/SecretariatTransferModal/SecretariatTransferModal";
import SecretariatCorespondencyNoteModal from "./Components/Modals/SecretariatCorespondencyNoteModal/SecretariatCorespondencyNoteModal";
import SecretariatNoteModal from "./Components/Modals/SecretariatNoteModal/SecretariatNoteModal";
import SecretariatContactModal from "./Components/Modals/SecretariatContactModal/SecretariatContactModal";
import ChangeProfilePhoneModal from "./Components/Modals/ChangeProfilePhoneModal/ChangeProfilePhoneModal";
import ContractModal from "./Components/Modals/ContractModal/ContractModal";
import CarModal from "./Components/Modals/CarModal/CarModal";
import OfferModal from "./Components/Modals/OfferModal/OfferModal";
import OfferCustomerPotenitalModal from "./Components/Modals/OfferCustomerPotenitalModal/OfferCustomerPotenitalModal";
import BusinessDocumentModal from "./Components/Modals/BusinessDocumentModal/BusinessDocumentModal";
import IsoModal from "./Components/Modals/IsoModal/IsoModal";
import MeasuringDeviceModal from "./Components/Modals/MeasuringDeviceModal/MeasuringDeviceModal";
import MeasuringDeviceVerificationModal from "./Components/Modals/MeasuringDeviceVerificationModal/MeasuringDeviceVerificationModal";
import DrawingModal from "./Components/Modals/DrawingModal/DrawingModal";
import ListOfDrawingsModal from "./Components/Modals/ListOfDrawingsModal/ListOfDrawingsModal";
import FixedAssetsTypeModal from "./Components/Modals/FixedAssetsTypeModal/FixedAssetsTypeModal";
import ComplaintModal from "./Components/Modals/ComplaintModal/ComplaintModal";
import FixedAssets from "./Components/Modals/FixedAssetsModal/FixedAssetsModal";
import { PrintDocumentRoute } from "./Routes/PrintDocument/PrintDocumnetRoute";
import PrintDocumentEmailModal from "./Components/Modals/PrintDocumentEmailModal/PrintDocumentEmailModal";
import FormManagementModal from "./Components/Modals/FormManagementModal/FormManagementModal";
import FormLaminModal from "./Components/Modals/FormLaminModal/FormLaminModal";
import AddExistingFormModal from "./Components/Modals/AddExistingFormModal/AddExistingFormModal";
import FormManagementElementModal from "./Components/Modals/FormManagementElementModal/FormManagementElementModal";

const Dashboard = lazy(() => import("./Containers/Dashboard/Dashboard"));
const Login = lazy(() => import("./Containers/Login/Login"));
const NotFound = lazy(() => import("./Containers/NotFound/NotFound"));

class App extends Component {
  async componentDidMount() {
    const { AuthFetch, setUser, setAuth, connectSocket, ws } = this.props;
    const uuid = Cookies.get("BMX_ID");

    if (uuid) {
      const { payload } = await AuthFetch({
        path: `/worker/${uuid}`,
        disableError: true,
      });
      if (payload?.data && payload?.status < 400) {
        setUser(payload.data);
        setAuth();
        if (!ws) {
          connectSocket(payload.data.uuid);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { errors, removeError } = this.props;

    if (!isEqual(errors, prevProps.errors)) {
      errors?.forEach((err) => {
        if (!["login"].includes(err.type)) {
          NotificationManager.error(
            err?.error?.message
              ? i18n.t(`global:${err?.error?.message?.replaceAll(" ", "")}`)
              : "",
            i18n.t("global:notification.error")
          );
          removeError(err);
        }
      });
    }
  }

  render() {
    return (
      <>
        <Router>
          <Suspense fallback={<BMXLoader />}>
            <Switch>
              <Redirect exact from="/" to="/dashboard" />
              <AuthRoute
                path="/dashboard"
                render={(props) => <Dashboard {...props} />}
              />
              <Route path="/login" render={(props) => <Login {...props} />} />
              {/*printDocuments*/}
              <Route
                path="/printDocument"
                render={() => <PrintDocumentRoute />}
              />
              <Route path="*" render={() => <NotFound />} />
            </Switch>
          </Suspense>
        </Router>
        {/* All modals in app:*/}
        <Modal />
        <ResetPasswordModal />
        <ChangeProfilePasswordModal />
        <ChangeProfilePhoneModal />
        <ChangeImageModal />
        <RegistryModal />
        <StatusModal />
        <SettingsModal />
        <AttachmentsModal />
        <FolderModal />
        <AttachmentsFolderModal />
        <AttachmentsFromStorageModal />
        <AttachmentsFromTemplateForm />
        <BMXConfirmModal />
        <AttachmentsEditFileModal />
        <TranslationModal />
        <AlertModal />
        <NoteModal />
        <DictionaryModal />
        <DictionaryValueModal />
        <DictionaryConnectModal />
        <RolesModal />
        <WorkerModal />
        <WorkerStaffModal />
        <StorageAddFile />
        <StorageEditFile />
        <StorageToUser />
        <GlobalAlertModal />
        <DepartmentModal />
        <ContractModal />
        <ContactsModal />
        <RepresentativesModal />
        <BranchesModal />
        <FullScreenDialog />
        <SecretariatCorespondenceModal />
        <SecretariatCorespondenceModalSended />
        <SettingsSecretaryModal />
        <SecretariatTransferModal />
        <SecretariatCorespondencyNoteModal />
        <SecretariatNoteModal />
        <SecretariatContactModal />
        <CarModal />
        <OfferModal />
        <IsoModal />
        <OfferCustomerPotenitalModal />
        <BusinessDocumentModal />
        <ComplaintModal />
        <FixedAssets />
        <PrintDocumentEmailModal />
        {/*Notify*/}
        <NotificationContainer />
        <MeasuringDeviceModal />
        <MeasuringDeviceVerificationModal />
        <DrawingModal />
        <ListOfDrawingsModal />
        <FormManagementModal />
        <FormLaminModal />
        <AddExistingFormModal />
        <FixedAssetsTypeModal />
        <FormManagementElementModal />
        <FixedAssetsYearModal />
      </>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      ws: state.socket.ws,
      errors: state.errors.errors,
    }),
    (dispatch) => ({
      AuthFetch: bindActionCreators(AuthFetch, dispatch),
      setUser: (user) => dispatch(setUser(user)),
      connectSocket: (userId) => dispatch(connectSocket(userId)),
      setAuth: () => dispatch(setAuth()),
      removeError: (error) => dispatch(removeError(error)),
    })
  )
)(App);
