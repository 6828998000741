import BMXModal from "../../../../Components/Modals/BMXModal/BMXModal";
import { connectModal } from "redux-modal";
import ListOfDrawingsModalForm from "../../Forms/ListOfDrawingsList/ListOfDrawingsList";

const ListOfDrawingsModal = (props) => {
  const formName = "ListOfDrawingsModalForm";
  const { handleHide, show } = props;

  return (
    <BMXModal
      open={show}
      width={"750px"}
      onClose={handleHide}
      header={props.header}
    >
      <ListOfDrawingsModalForm
        handleHide={handleHide}
        formName={formName}
        {...props}
      />
    </BMXModal>
  );
};

export default connectModal({ name: "ListOfDrawingsModal" })(
  ListOfDrawingsModal
);
