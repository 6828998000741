import { useState } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXAsynchronous from "../../../../../Components/Inputs/BMXAsynchronous/BMXAsynchronous";
import BMXButton from "../../../../../Components/Buttons/BMXButton/BMXButton";

const AddExistingLaminFormStepOne = (props) => {
  const { t } = useTranslation();
  const { values, onSubmit } = props;
  const [options, setOptions] = useState([]);

  const getData = async (searchTerm) => {
    const data = GlobalAuthFetch({
      path: `/form/findByFN/${searchTerm}`,
    });

    const [drawingListPayload] = await Promise.all([data]);
    const drawingList = drawingListPayload?.payload?.data?.map((p) => {
      return { uuid: p.uuid, value: `${p.formId} - ${p?.name}` };
    });
    if (drawingList?.length > 0) {
      setOptions(drawingList);
    } else {
      setOptions([]);
    }
  };

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <Field
        currentValues={values}
        className="form"
        component={BMXAsynchronous}
        label={`${t("global:formLamin.formId")}`}
        name="form"
        options={options}
        getData={getData}
        data-cy="form"
        disablePortal={true}
      />
      <div className="mt-2">
        <BMXButton
          data-cy="save"
          type="subbmit"
          onClick={() => onSubmit(values, props)}
          bmxclass="Success"
        >
          {t("global:save")}
        </BMXButton>
      </div>
    </div>
  );
};

export default AddExistingLaminFormStepOne;
