import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXCardModal from "../../../../Components/Modals/BMXCardModal/BMXCardModal";
import FormManagementElementForm from "../../Forms/FormManagementElementForm/FormManagementElementForm";
import "./FormManagementElementModal.scss"

const FormManagementElementModal = (props) => {
  const { handleHide, show } = props;

  const { t } = useTranslation();

  return (
    <BMXCardModal
      className="FormManagementElementModal"
      width="500px"
      height="425px"
      header={t("global:formManagementElementModal.header")}
      open={show}
      onClose={handleHide}
    >
      <FormManagementElementForm {...props}/>
    </BMXCardModal>
  );
};

export default connectModal({ name: "formManagementElementModal" })(FormManagementElementModal);
