import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import { show } from "redux-modal";
const ContractFormStepOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contractTypeSelect, setContractTypeSelect] = useState([]);
  const [contacts, setContacts] = useState([]);
  const { values } = props;
  const REGISTRY_NAME = "contact";
  const loadContacts = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);
    setContacts(resContact?.payload?.data);
  };

  const getSelectedContact = (contact) => {
    if (!contact) return null;
    return contacts.find(
      (el) => el.uuid === contact.value || el.uuid === contact.uuid
    );
  };

  useEffect(() => {
    loadContacts();
    const fetchData = async () => {
      const {
        payload: {
          data: { dictionaryEntries },
        },
      } = await GlobalAuthFetch({ path: "/dictionary/contractType/fieldName" });
      setContractTypeSelect(dictionaryEntries);
    };
    fetchData();
  }, [dispatch]);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:contract.basicInfo")} />
      <Row>
        <Col xs={4}>
          <Field
            component={BMXTextField}
            label={`${t("global:contract.numberContract")}*`}
            name="numberContract"
            data-cy="numberContract"
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:contract.signDate")}*`}
            name="signDate"
            data-cy="signDate"
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:contract.expirationDate")}`}
            name="expirationDate"
            data-cy="expirationDate"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:contract.title")}*`}
            name="title"
            data-cy="title"
          />
        </Col>
        <Col xs={{ span: 4, offset: 2 }}>
          <Field
            component={BMXSelectField}
            values={contractTypeSelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={`${t("global:contract.contractType")}*`}
            name="contractType"
            data-cy="contractType"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            data-cy="contact"
            component={BMXSelectField}
            values={contacts.map((el) => el)}
            label={`${t("global:contract.contactInfo")}*`}
            name="contact"
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="representative"
            beforeIcon="people"
            afterIcon="add"
            afterIconSize="18px"
            afterIconClick={() => {
              const contactUuid =
                values?.contact?.uuid || values?.contact?.value;
              const updateSingleRegistry = () =>
                updateDashboardByName(REGISTRY_NAME, contactUuid);
              dispatch(
                show("RepresentativesModal", {
                  loadData: loadContacts,
                  header: t("global:contact.representativeAdd"),
                  updateSingleRegistry,
                  uuid: contactUuid,
                })
              );
            }}
            disabled={!values?.contact}
            component={BMXSelectField}
            values={getSelectedContact(values?.contact)?.representatives?.map(
              (el) => ({
                value: el.uuid,
                name: `${el.name || ""} ${el.lastname || ""}`,
              })
            )}
            label={`${t(
              "global:secretariatCorespondenceContact.representative"
            )}`}
            name="representative"
            renderValue={(el) => {
              return `${el.name || ""} ${el.lastname || ""}`;
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            multiline={true}
            label={`${t("global:contract.description")}*`}
            name="description"
            data-cy="description"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContractFormStepOne;
