import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import "./DetailsTable.scss";
import { useRef } from "react";

const DetailsTableCell = (props) => {
  const { header, row } = props;

  const columnRef = useRef();

  const shouldShowTooltip = () => {
    return (
      columnRef.current?.offsetWidth - 40 < columnRef.current?.lastChild?.offsetWidth
    );
  };

  return (
    <Tooltip
      title={
        header?.key !== "Buttons" && shouldShowTooltip()
          ? row[header?.key]
          : null
      }
      placement="bottom-start"
    >
      <TableCell
        ref={columnRef}
        align="left"
        style={{
          maxWidth: header?.width,
          minWidth: header?.width,
        }}
      >
        {header?.date && (
          <span className="line-clamp">
            {moment(row[header?.key]).format("DD-MM-YYYY")}
          </span>
        )}
        {!header?.date && (
          <span
            className={`${
              header?.key === "Buttons" ? "DetailsTableButtons" : ""
            }`}
          >
            {row[header?.key]}
          </span>
        )}
      </TableCell>
    </Tooltip>
  );
};

const DetailsTable = (props) => {
  const { headers, tableData } = props;

  return (
    <>
      <div className="DetailsTable">
        <TableContainer>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers?.map((header) => (
                  <TableCell
                    key={header.key}
                    align="left"
                    style={{ maxWidth: header.width, minWidth: header.width }}
                  >{`${header.title}`}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row) => (
                <TableRow className="TableDataRow" key={row.id}>
                  {headers?.map((header) => (
                    <DetailsTableCell header={header} row={row} />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default DetailsTable;
