import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { NotificationManager } from "react-notifications";
import { cloneDeep, set } from "lodash";
import {
  EmailValidators,
  PhoneValidators,
} from "../../../../Utils/Validators/Validators";
const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { contactsReducer } = store.getState();
  const { uuid, updateTableRegistry, loadData, update, updateSingleRegistry } =
    props;
  const data = cloneDeep(values);

  if (values?.clientGuardian?.value)
    set(data, "clientGuardian.uuid", values?.clientGuardian?.value);

  set(data, "contactType", values?.contactType?.value);

  let payload;

  if (!update) {
    if (!contactsReducer?.availableStatuses) {
      const { payload } = await GlobalAuthFetch({
        path: `/registry/getByUrl/contact`,
      });
      data.status = payload.data.availableStatuses.find(
        (el) => !!el.isSelected
      );
    } else {
      data.status = contactsReducer?.availableStatuses.find(
        (el) => !!el.isSelected
      );
    }

    const res = await GlobalAuthFetch({
      path: `/contact`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    delete data.updatedAt;
    delete data.createdAt;

    const res = await GlobalAuthFetch({
      path: `/contact/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    props.setErrorHeader(1, true);

    return;
  }

  props.setErrorHeader(0, false);
  props.setErrorHeader(1, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};
  const errorsStep1 = {};

  if (!values.name) {
    errorsStep0.name = t("global:contacts.errors.name");
  }
  if (!values.city) {
    errorsStep0.city = t("global:contacts.errors.city");
  }
  if (!values.postCode) {
    errorsStep0.postCode = t("global:contacts.errors.postCode");
  }
  if (!values.street) {
    errorsStep0.street = t("global:contacts.errors.street");
  }
  if (!values.houseNumber) {
    errorsStep0.houseNumber = t("global:contacts.errors.houseNumber");
  }

  if (values.phoneNumber && PhoneValidators(values.phoneNumber)) {
    errorsStep1.phoneNumber = t("global:contacts.errors.phoneNumberValid");
  }

  if (values.email && EmailValidators(values.email)) {
    errorsStep1.email = t("global:contacts.errors.emailValid");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }

  return { ...errorsStep0, ...errorsStep1 };
};
