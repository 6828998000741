export const submit = (values, props) => {
  const { handleHide, setContactValues, contacts, setSubject } = props;
  const contact = contacts.find(
    (el) =>
      el.uuid === values?.contact?.value || el.uuid === values?.contact?.uuid
  );
  const data = {
    contact,
    branch: contact?.branches?.find(
      (el) =>
        el.uuid === values?.branch?.value || el.uuid === values?.branch?.uuid
    ),
    representative: contact?.representatives?.find(
      (el) =>
        el.uuid === values?.representative?.value ||
        el.uuid === values?.representative?.uuid
    ),
  };

  setContactValues(data);
  setSubject(values.subject);
  handleHide();
};

export const validate = (values) => {
  const errors = {};

  // if (!values.name) {
  //     errors.name = t('global:fieldRequired');
  // }

  // if (!values.status) {
  //     errors.status = t('global:fieldRequired');
  // }

  return errors;
};
