import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import "./BMXRadioButton.scss";

const BMXRadioButton = (props) => {
  const { options, input, ...rest } = props;

  return (
    <div className="BMXRadioButton">
      {options.map((option) => (
        <div className={`RadioElement ${rest.disabled?"disabled":""}`}>
          <Radio
            {...rest}
            {...input}           
            checked={input.value === option.value}
            value={option.value}
            inputProps={{ "aria-label": option.value }}
            id={option.value}
          />
          <label for={option.value}  type="radio">{option.name}</label>
        </div>
      ))}
    </div>
  );
};

export default BMXRadioButton;

BMXRadioButton.defaultProps = {
  size: "sm",
};
BMXRadioButton.propTypes = {
  /**
       Props określający rozmiar przycisku || small || medium || large
      */
  size: PropTypes.string,
  /**
       Props określający czy przycisk ma być aktywny lub nie
      */
  disabled: PropTypes.bool,
  /**
      Props renderuje opakowane elementy 
     */
  children: PropTypes.node,
  /**
        Props z opcjami radio
    */
  options: PropTypes.array.isRequired,
};
