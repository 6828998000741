import React from 'react';

function App() {
  return (
    <>
      <header className="App-header">
        <p>
          Mobile
        </p>
      </header>
    </>
  );
}

export default App;
