import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import { show } from "redux-modal";
import { useDispatch } from "react-redux";

const BusinessDocumentFormStepOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // NEW COMPONENET
  const [contacts, setContacts] = useState([]);
  const REGISTRY_NAME = "contact";
  const loadContacts = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);
    setContacts(resContact?.payload?.data);
  };
  const { values } = props;

  const getSelectedContact = (contact) => {
    if (!contact) return null;
    return contacts.find(
      (el) => el.uuid === contact.value || el.uuid === contact.uuid
    );
  };

  useEffect(() => {
    loadContacts();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:document.basicInformation")} />
      <Row>
        <Col xs={6}>
          <Field
            data-cy="contact"
            component={BMXSelectField}
            values={contacts?.map((el) => el)}
            label={`${t("global:document.contact.contact")}*`}
            name="contact"
          />
        </Col>
        <Col xs={{ span: 6 }}>
          <Field
            data-cy="representative"
            beforeIcon="people"
            afterIcon="add"
            afterIconSize="18px"
            afterIconClick={() => {
              const contactUuid =
                values?.contact?.uuid || values?.contact?.value;
              const updateSingleRegistry = () =>
                updateDashboardByName(REGISTRY_NAME, contactUuid);
              dispatch(
                show("RepresentativesModal", {
                  loadData: loadContacts,
                  header: t("global:document.representativeAdd"),
                  updateSingleRegistry,
                  uuid: contactUuid,
                })
              );
            }}
            disabled={!values?.contact}
            component={BMXSelectField}
            values={getSelectedContact(values?.contact)?.representatives?.map(
              (el) => ({
                value: el.uuid,
                name: `${el.name || ""} ${el.lastname || ""}`,
              })
            )}
            label={`${t("global:document.representative")}`}
            name="representative"
            renderValue={(el) => {
              return `${el.name || ""} ${el.lastname || ""}`;
            }}
          />
        </Col>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:document.title")}*`}
            name="title"
            data-cy="title"
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:document.expirationDate")}*`}
            name="expirationDate"
            data-cy="expirationDate"
          />
        </Col>
        <Col xs={{ span: 2 }}>
          <Field
            component={BMXTextField}
            fieldtype={"checkbox"}
            label={`${t("global:document.isIndefinite")}`}
            name="indefinite"
            data-cy="indefinite"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:document.description")}*`}
            name="description"
            data-cy="description"
          />
        </Col>
      </Row>
    </div>
  );
};

export default BusinessDocumentFormStepOne;
