import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXAsynchronous from "../../../../../Components/Inputs/BMXAsynchronous/BMXAsynchronous";
import "./FormLaminFormStepOne.scss";
import DetailsTable from "../../../Tables/DetailsTable/DetailsTable";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { hide, show } from "redux-modal";
import { useDispatch } from "react-redux";

const FormLaminFormStepOne = (props) => {
  const { t } = useTranslation();
  const { values, initialValues, form } = props;
  const dispatch = useDispatch();
  const ShowModal = (name, props) => dispatch(show(name, props));
  const HideModal = (name) => dispatch(hide(name));
  const [projectSelect, setProjectSelect] = useState([]);
  const [typeSelect, setTypeSelect] = useState([]);
  const [selectedDrawing, setSelectedDrawing] = useState(
    initialValues?.drawings || []
  );

  const headers = [
    {
      key: "drawingNumber",
      width: 125,
      title: t("global:LaminFormStepOneTableDrawingNumber"),
    },
    {
      key: "elementName",
      width: 125,
      title: t("global:LaminFormStepOneTableElementName"),
    },
    {
      key: "Buttons",
      width: 80,
      title: "",
    },
  ];

  useEffect(() => {
    form.change("drawings", selectedDrawing);
    // eslint-disable-next-line
  }, [selectedDrawing]);

  const [contacts, setContacts] = useState([]);

  const loadContacts = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);
    setContacts(resContact?.payload?.data);
  };

  const [options, setOptions] = useState([]);

  const getData = async (searchTerm) => {
    const data = GlobalAuthFetch({
      path: `/drawing/findByDN/${searchTerm}`,
      disableError: true,
    });

    const [drawingListPayload] = await Promise.all([data]);
    const drawingList = drawingListPayload?.payload?.data?.map((p) => {
      return {
        value: `${p.drawingNumber} - ${p.elementName}`,
        ...p,
      };
    });
    setOptions(drawingList||[]);
  };

  const openDrawing = (uuid) => {
    if (uuid) {
      let origin = window.location.origin;
      origin += `/#/dashboard/drawing/${uuid}`;
      window.open(origin, "_blank", "noreferrer");
    }
  };

  useEffect(() => {
    loadContacts();

    // LOADING DICTIONARIS
    const fetchData = async () => {
      // IMPORT DICTIONARIES IF IT NECESSARY
      const projectFetch = GlobalAuthFetch({
        path: "/dictionary/projectType/fieldName",
      });
      const [projectPayload] = await Promise.all([projectFetch]);
      setProjectSelect(
        projectPayload?.payload?.data?.dictionaryEntries?.map((e) => ({
          value: e.uuid,
          name: e.entryName,
          ...e,
        }))
      );

      const typeFetch = GlobalAuthFetch({
        path: "/dictionary/type/fieldName",
      });
      const [typePayload] = await Promise.all([typeFetch]);
      setTypeSelect(typePayload?.payload?.data?.dictionaryEntries);
    };

    fetchData();
  }, []);

  return (
    <div
      className="FormLaminFormStepOne"
      style={{ justifyContent: "flex-start" }}
    >
      <div>
        <Row>
          <Col xs={7}>
            <BMXSeparator title={t("global:drawing.basicInformation")} />
          </Col>
        </Row>

        <Row>
          <Col xs={3}>
            <Field
              component={BMXTextField}
              label={`${t("global:formLamin.formId")}*`}
              name="formId"
              data-cy="formId"
            />
          </Col>
          <Col xs={3}>
            <Field
              component={BMXTextField}
              label={`${t("global:formLamin.otherId")}`}
              name="otherId"
              data-cy="otherId"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={7}>
            <Field
              currentValues={values}
              className="drawing"
              component={BMXAsynchronous}
              label={`${t("global:formLamin.link")}*`}
              name="drawing"
              options={options}
              getData={getData}
              data-cy="drawing"
              afterIcon="arrow_forward_icon"
              afterIconSize="1.3rem"
              afterIconClick={() => {
                if(values?.drawing?.uuid){
                  setSelectedDrawing((prev) => [
                    values?.drawing,
                    ...prev.filter((el) => el.uuid !== values?.drawing?.uuid),
                  ]);
                  form.change("drawing", null);
                }                
              }}
              afterTooltipText="global:formLamin.link"
              afterIconClass="primary text "
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Field
              component={BMXTextField}
              label={`${t("global:formLamin.name")}*`}
              name="name"
              data-cy="name"
            />
          </Col>
          <Col xs={{ span: 4 }}>
            <Field
              data-cy="contact"
              component={BMXSelectField}
              values={contacts?.map((el) => el)}
              label={`${t("global:drawing.contact")}*`}
              name="contact"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Field
              component={BMXSelectField}
              data-cy="projects"
              values={projectSelect}
              initialValues={{
                projects: initialValues?.projects?.map((e) => ({
                  value: e.uuid,
                  name: e.entryName,
                  ...e,
                })),
              }}
              selectedValues={values}
              multiple
              displayEmpty
              label={`${t("global:formLamin.project")}*`}
              name="projects"
              renderValue={(el) => {
                return el.map((e) => e.name).join(", ");
              }}
            />
          </Col>
          <Col xs={{ span: 4, offset: 4 }}>
            <Field
              component={BMXTextField}
              fieldtype="checkbox"
              label={`${t("global:formLamin.isLamin")}`}
              name="lamin"
              data-cy="isLamin"
            />
          </Col>
          <Col xs={4}>
            <Field
              component={BMXSelectField}
              values={typeSelect?.map((el) => ({
                name: el.entryName,
                value: el.uuid,
              }))}
              label={`${t("global:formLamin.type")}*`}
              name="type"
              data-cy="type"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={8}>
            <Field
              component={BMXTextField}
              label={`${t("global:formLamin.comments")}`}
              name="comments"
              data-cy="comments"
            />
          </Col>
        </Row>
        <div className="TableArea">
          <DetailsTable
            headers={headers}
            tableData={selectedDrawing.map((item) => ({
              ...item,
              Buttons: [
                <div>
                  <Tooltip title={t("global:formLamin.delete")}>
                    <IconButton
                      onClick={() =>
                        ShowModal("ConfirmModal", {
                          onSubmit: () => {
                            setSelectedDrawing((prev) => [
                              ...prev.filter((el) => el.uuid !== item?.uuid),
                            ]);
                            HideModal("ConfirmModal");
                          },
                        })
                      }
                    >
                      <Icon fontSize="small" className="error text">
                        delete
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </div>,
                <div>
                  <Tooltip title={t("global:formLamin.openForm")}>
                    <IconButton>
                      <Icon
                        className="primary text"
                        onClick={() => openDrawing(item.uuid)}
                        fontSize={"small"}
                      >
                        arrow_forward_icon
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </div>,
              ],
            }))}
          />
        </div>
      </div>
    </div>
  );
};

export default FormLaminFormStepOne;
