import {useState,useEffect, useReducer} from 'react';


export const useWindowsMedia=()=>{

    const [windowDetails, setWindowDetails] = useState({windowDetails:window});

    const getWindow = () => {
        setWindowDetails({windowDetails:window});
    }

    useEffect(()=>{
        window.addEventListener("resize",getWindow);
        return () => window.removeEventListener('resize',getWindow);
    },[])


    return windowDetails;

}

export const useForceUpdate = () => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    return forceUpdate;
  }