import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import "./SecretariatCorespondenceModalSended.scss";
import { show } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import { useEffect } from "react";
import { emailExtarctor } from "../../../../Utils/Emails/EmailsUtils";
import { useRef } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import {
  downloadFromBloob,
  saveFilesAsZip,
} from "../../../../Utils/Attachments/Attachments";
import {
  Card,
  Icon,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { t } from "i18next";
import SecretariatCorespondenceFormSended from "../../Forms/SecretariatCorespondenceFormSended/SecretariatCorespondenceFormSended";
import {
  SecretaryCorespondenceFormContext,
  useSecretaryCorespondenceFormContext,
} from "../../../../Contexts/SecretaryCorespondenceFormContext";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { DL } from "../../../../Utils/Envelopes/Envelopes.js";
import { C5 } from "../../../../Utils/Envelopes/Envelopes.js";
import BMXQuillArea from "../../../../Components/BMXQuillArea/BMXQuillArea";
import { edit } from "../../../../Utils/Permissions";
const formName = "SecretariatCorespondenceFormSended";

const TabsArea = (props) => {
  const { steps, step, setStep } = useSecretaryCorespondenceFormContext();

  return (
    <Tabs
      className="TabsArea"
      value={step}
      onChange={(e, index) => setStep(index)}
    >
      {steps.map((item, index) => (
        <Tab data-cy={item.title} label={item.title} />
      ))}
    </Tabs>
  );
};

const AttachmnetArea = (props) => {
  const {
    attachments,
    attachmentsList,
    number,
    update,
    subject,
    setAttachmentValues,
    attachmentValues,
  } = props;
  const refAttachmnetArea = useRef();

  const downloadAttachment = async (file) => {
    let data = null;
    if (!update) {
      const {
        payload: { data: _data },
      } = await GlobalAuthFetch({
        path: `/mail/downloadAttachment/${number}/${file}`,
        addons: { responseType: "blob" },
      });
      data = _data;
      if (Boolean(data)) {
        downloadFromBloob(data, file, {
          document: refAttachmnetArea.current,
        });
      }
      return;
    }

    const {
      payload: { data: _data },
    } = await GlobalAuthFetch({
      path: `/attachments/${file?.uuid}`,
      addons: { responseType: "blob" },
    });
    data = _data;
    if (Boolean(data)) {
      downloadFromBloob(data, file?.name, {
        document: refAttachmnetArea.current,
      });
    }
  };
  const downloadAllAttachments = async () => {
    const dataArray = [];

    if (attachmentValues?.length > 0) {
      for (const file of attachmentValues) {
        const attachmentObj = {
          data: file,
          name: file?.name,
        };
        dataArray.push(attachmentObj);
      }
    }

    if (!update) {
      const allAttachments = attachmentsList;

      for (let attachment of allAttachments) {
        const {
          payload: { data: _data },
        } = await GlobalAuthFetch({
          path: `/mail/downloadAttachment/${number}/${attachment}`,
          addons: { responseType: "blob" },
        });
        const attachmentObj = {
          data: _data,
          name: attachment,
        };
        dataArray.push(attachmentObj);
      }
      const fileName = `${t(
        "global:secreateryCorespondence.attachmentSaveAll"
      )}${subject || ""}`;
      await saveFilesAsZip(dataArray, fileName);
      return;
    }

    const allAttachments = attachments;

    for (let file of allAttachments) {
      const {
        payload: { data: _data },
      } = await GlobalAuthFetch({
        path: `/attachments/${file?.uuid}`,
        addons: { responseType: "blob" },
      });
      const attachmentObj = {
        data: _data,
        name: file?.name,
      };
      dataArray.push(attachmentObj);
    }
    const fileName = `${t("global:secreateryCorespondence.attachmentSaveAll")}${
      subject || ""
    }`;
    await saveFilesAsZip(dataArray, fileName);
    return;
  };

  const downloadAttachmentFromBrowser = (el) => {
    downloadFromBloob(el, el?.name);
  };

  const addNextAttachment = async (el) => {
    const {
      target: { files },
    } = el;
    setAttachmentValues((prevValues) => [...prevValues, ...files]);
  };

  return (
    <div className="AttachmentsArea" ref={refAttachmnetArea}>
      {attachmentValues?.map((el) => (
        <p
          className="AttachmentItem"
          onClick={() => downloadAttachmentFromBrowser(el)}
        >
          <Icon className="grey text">attach_file</Icon>
          <span className="text">{el?.name}</span>
        </p>
      ))}
      {attachmentsList?.map((el) => (
        <p className="AttachmentItem" onClick={() => downloadAttachment(el)}>
          <Icon className="grey text">attach_file</Icon>
          <span className="text">{el}</span>
        </p>
      ))}
      {attachments?.map((el) => (
        <p className="AttachmentItem" onClick={() => downloadAttachment(el)}>
          <Icon className="grey text">attach_file</Icon>
          <span className="text">{el?.name}</span>
        </p>
      ))}
      <div className="AttachmentButtons">
        <IconButton data-cy="buttons-label" component="label">
          <input
            onChange={addNextAttachment}
            id="file"
            multiple
            hidden
            type="file"
          />
          <Icon
            className="grey text"
            style={{ fontSize: "18px", padding: "0" }}
          >
            attach_file
          </Icon>
        </IconButton>

        {(attachmentValues?.length > 0 ||
          attachmentsList?.length > 0 ||
          attachments?.length > 0) && (
          <IconButton onClick={downloadAllAttachments}>
            <Icon
              className="grey text"
              style={{ fontSize: "18px", padding: "0" }}
            >
              download
            </Icon>
          </IconButton>
        )}
      </div>
      <div className="AttachmentCounter">
        {t("global:secreateryCorespondence.attachmentCounter")}:{" "}
        {(attachmentsList?.length || attachments?.length || 0) +
          attachmentValues?.length || 0}
      </div>
    </div>
  );
};

const SecretariatCorespondenceModalSended = (props) => {
  const {
    handleHide,
    show: open,
    update,
    updateSocketSecretary,
    loadData,
    initialValues,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [contactValues, setContactValues] = useState({
    contact: initialValues?.contact,
    branch: initialValues?.branch,
    representative: initialValues?.representative,
  });
  const [emailHtml, setEmailHtml] = useState();
  const [attachmentValues, setAttachmentValues] = useState([]);
  const email = initialValues?.email;

  const [subject, setSubject] = useState(email?.subject);

  const [isSubmit, setIsSubmit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [contactError, setContactError] = useState(false);
  const rights = [
    { name: "SECRETARY_READ" },
    { name: "SECRETARY_CREATE" },
    { name: "SECRETARY_UPDATE" },
    { name: "SECRETARY_DELETE" },
  ];
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { title: t("global:secreateryCorespondence.corespondence"), error: false },
    { title: t("global:secreateryCorespondence.transfered"), error: false },
    { title: t("global:secreateryCorespondence.notes"), error: false },
  ]);
  const settings = useSelector((state) => state.settingsReducer.settings);
  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

  let fromEmail = email?.from ? emailExtarctor(email?.from) : "";

  if (Boolean(email?.from) && Boolean(contactValues?.contact?.name))
    fromEmail = fromEmail + " - ";
  if (Boolean(contactValues?.contact?.name))
    fromEmail = fromEmail?.concat("", contactValues?.contact?.name);
  if (Boolean(contactValues?.branch?.name))
    fromEmail = fromEmail?.concat(", ", contactValues?.branch?.name);
  if (Boolean(contactValues?.representative?.name))
    fromEmail = fromEmail?.concat(", ", contactValues?.representative?.name);
  if (Boolean(contactValues?.representative?.lastname))
    fromEmail = fromEmail?.concat(" ", contactValues?.representative?.lastname);

  const openContactModal = () => {
    dispatch(
      show("SecretariatContactModal", {
        setContactValues,
        contactValues,
        setSubject,
        subject,
      })
    );
  };

  const header = (
    <div className="SecretariatCorespondenceModalSendedHeader">
      <Tooltip
        title={t("global:secreateryCorespondence.editContact")}
        placement="top"
      >
        <IconButton
          className="editButton"
          onClick={openContactModal}
          size="small"
        >
          <Icon style={{ fontSize: "0.938rem" }}>edit</Icon>
        </IconButton>
      </Tooltip>
      <div onClick={openContactModal} className="overlay"></div>
      <div className={`Header ${!contactError ? "" : "error text"}`}>
        <label className={!fromEmail ? "line-after" : ""}>
          <span className="header-text" onClick={openContactModal}>
            {t("global:secreateryCorespondence.toReceiver")}:
          </span>
        </label>
        <label className={!subject ? "line-after" : ""}>
          <span className="header-text" onClick={openContactModal}>
            {t("global:secreateryCorespondence.topic")}:
          </span>
        </label>
        <Tooltip
          title={t("global:secreateryCorespondence.editContact")}
          placement="top"
        >
          <IconButton
            className="editButton"
            onClick={openContactModal}
            size="small"
          >
            <Icon style={{ fontSize: "0.938rem" }}>edit</Icon>
          </IconButton>
        </Tooltip>
      </div>
      <div className="Data">
        <label>{fromEmail}</label>
        <label>{subject}</label>
      </div>
    </div>
  );

  const [sendMail, setSendMail] = useState(false);
  const [isSendActive, setIsSendActive] = useState(false);
  const [envelopeSize, setEnvelopeSize] = useState(null);
  const [envelopeValidationBoolean, setEnvelopeValidationBoolean] =
    useState(true);

  const contactValidation = () => {
    if (
      contactValues.branch !== undefined &&
      contactValues.contact !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (envelopeSize !== undefined && contactValidation()) {
      setEnvelopeValidationBoolean(false);
    } else {
      setEnvelopeValidationBoolean(true);
    }
    // eslint-disable-next-line
  }, [envelopeSize, contactValues]);

  const buttons = (
    <>
      <div>
        <BMXButton
          bmxclass={"Secondary"}
          disabled={envelopeValidationBoolean}
          onClick={() => {
            const mywindow = window.open(
              "",
              "",
              `height=${window.innerHeight},width=${window.innerWidth}`
            );

            const envelopeDataSender = {
              logo: settings.find((e) => e.name === "LOGO").value || "",
              companyName:
                settings.find((e) => e.name === "COMPANY_NAME").value || "",
              address:
                settings.find((e) => e.name === "COMPANY_ADDRESS").value || "",
              postCode:
                settings.find((e) => e.name === "COMPANY_POST_CODE").value ||
                "",
              city: settings.find((e) => e.name === "COMPANY_CITY").value || "",
              website: settings.find((e) => e.name === "WEBSITE").value || "",
            };

            const envelopeDataReceiver = {
              companyName: contactValues.contact.name || "",
              address: `${contactValues.branch.address}` || "",
              postCode: contactValues.branch.postCode || "",
              city: contactValues.branch.city || "",
            };

            if (envelopeSize.value === "DL") {
              mywindow.document.write(
                DL(envelopeDataSender, envelopeDataReceiver)
              );
              setTimeout(() => {
                mywindow.print();
                mywindow.close();
              }, 250);
            }

            if (envelopeSize.value === "C5") {
              mywindow.document.write(
                C5(envelopeDataSender, envelopeDataReceiver)
              );
              setTimeout(() => {
                mywindow.print();
                mywindow.close();
              }, 250);
            }
          }}
        >
          {t("global:envelope-print")}
        </BMXButton>
      </div>

      <div>
        {edit(rights) && (
          <BMXButton
            data-cy="save"
            type="subbmit"
            bmxclass="Success"
            disabled={disableButton}
            onClick={() => {
              setIsSubmit(true);
              setSendMail(false);
            }}
            form={formName}
          >
            {t("global:save")}
          </BMXButton>
        )}

        {isSendActive && edit(rights) && (
          <BMXButton
            style={{ marginLeft: "1rem" }}
            type="subbmit"
            bmxclass="Send"
            disabled={disableButton}
            onClick={() => {
              setIsSubmit(true);
              setSendMail(true);
            }}
            form={formName}
          >
            {t("global:send-text")}
          </BMXButton>
        )}
      </div>
    </>
  );

  const onClose = () => {
    if (Boolean(initialValues?.corespondenceNumber?.number)) return;

    const button = document.getElementById("numberButton");
    if (!update && Boolean(button)) {
      //Deleted non used number
      GlobalAuthFetch({
        path: "/correspondenceNumber/KW",
        method: "DELETE",
        data: button.innerText,
      });
    }
    handleHide();
  };

  return (
    <Modal
      className="SecretariatCorespondenceModalSended"
      open={open}
      onClose={onClose}
    >
      <Card>
        <div>
          <h4>{header}</h4>
          <CloseIcon className="CloseIcon" fontSize="small" onClick={onClose} />
        </div>
        <div className="SecretariatCorespondenceModalSendedBody">
          <div className="FormArea">
            <SecretaryCorespondenceFormContext.Provider
              value={{
                isSubmit,
                steps,
                step,
                setStep,
                initialValues,
                setErrorHeader,
                formName,
                handleHide,
                update,
                updateSocketSecretary,
                contactValues,
                attachmentValues,
                setIsSendActive,
                setEnvelopeSize,
                subject,
                loadData,
                emailHtml,
                sendMail,
                setDisableButton,
                setContactError,
              }}
            >
              <TabsArea />
              <div className="FormBodyArea">
                <SecretariatCorespondenceFormSended />
              </div>
            </SecretaryCorespondenceFormContext.Provider>
          </div>
          <BMXQuillArea
            content={initialValues?.text}
            onChangeText={(editor) => {
              setEmailHtml(editor.root.innerHTML);
            }}
            className="EmailArea"
          />
          <AttachmnetArea
            attachmentValues={attachmentValues}
            setAttachmentValues={setAttachmentValues}
            update={update}
            number={email?.number}
            attachments={email?.attachments}
            attachmentsList={email?.attachmentsList}
            subject={subject}
          />
        </div>
        <div className="ModalButton">{buttons}</div>
      </Card>
    </Modal>
  );
};

export default connectModal({ name: "SecretariatCorespondenceModalSended" })(
  SecretariatCorespondenceModalSended
);

SecretariatCorespondenceModalSended.propTypes = {
  header: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  handleHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  email: PropTypes.object.isRequired,
};

SecretariatCorespondenceModalSended.default = {
  email: {},
};
