import { PureComponent, Fragment } from "react";
import { compose } from "redux";
import { Icon, IconButton, Pagination } from "@mui/material";
import BMXSearchField from "../../../../Components/Inputs/BMXSearchField/BMXSearchField";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./SecondaryTable.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { get, isEqual } from "lodash";
import BMXChip from "../../../../Components/Chip/BMXChip";

const getRenderItem =
  (items, headers, select, onClickEffect) => (provided, snapshot, rubric) =>
    (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        key={`ContentTable-${rubric.source.index}`}
        onClick={(e) => onClickEffect(e, items[rubric.source.index])}
        className={`Data ${select ? "select" : ""}`}
      >
        {headers?.map((header, idx) => (
          <span
            className="DataItems"
            key={`${items[rubric.source.index][header.key]}-${idx}`}
            style={getHeaderStyles(snapshot.isDragging, header)}
          >
            <span>{items[rubric.source.index][header.key]}</span>
          </span>
        ))}
      </div>
    );

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getHeaderStyles = (isDragging, header) => ({
  maxWidth: header.width,
  minWidth: header.width,
  justifyContent: header.justifyContent,
  display: "flex !important",
  flex: "1 1 0",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? "rgb(0 0 0 / 8%)" : "",
  display: isDragging ? "flex" : "",
  alignItems: isDragging ? "center" : "",
  padding: isDragging ? "1rem" : "",
  // styles we need to apply on draggables
  ...draggableStyle,
});

class SecondaryTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      sortTable: [],
      page: 1,
      data: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onClickEffect = async (e, data) => {
    const { select, onClickRow } = this.props;
    if (onClickRow) {
      onClickRow(data);
    }
    if (select) {
      const x = e.clientX - e.target.getBoundingClientRect().left;
      const y = e.clientY - e.target.getBoundingClientRect().top;
      let circles = document.createElement("span");
      circles.className = "AnimCircle";
      circles.style.left = x + "px";
      circles.style.top = y + "px";
      e.currentTarget.appendChild(circles);
      setTimeout(() => {
        circles.remove();
      }, 400);
      const datas = document.getElementsByClassName("Data selected");
      for (const el of datas) {
        el.classList.remove("selected");
      }
      e.currentTarget.classList.add("selected");
      select(data);
    }
  };

  componentDidMount() {
    const { headers, data } = this.props;
    this.setState({
      data: data,
      search: "",
      sortTable: headers.map((e) => ({
        key: e.key,
        sort: "des",
        selected: false,
      })),
    });
  }

  onChangeSort = (header) => {
    const { sortChange } = this.props;
    const { sortTable } = this.state;
    if (header.sort) {
      this.setState(
        {
          sortTable: sortTable.map((el) =>
            el.key === header.key
              ? {
                  key: el.key,
                  sort: this.sortChanger(el.sort),
                  selected: true,
                }
              : { ...el, selected: false }
          ),
        },
        () => {
          sortChange(
            header.key,
            sortTable.find((e) => e.key === header.key).sort
          );
        }
      );
    }
  };

  onChangePage = (el, val) => {
    this.setState({
      page: val,
    });
  };

  sortChanger = (type) => {
    switch (type) {
      case "asc":
        return "des";
      default:
        return "asc";
    }
  };

  onChange = (e) => {
    this.setState({ search: e.target.value });
  };

  onDragEnd(result) {
    const { data, draggable } = this.props;
    if (!result.destination) {
      return;
    }

    const items = reorder(data, result.source.index, result.destination.index);

    draggable(items);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, searchFunction, selected } = this.props;
    const { search } = this.state;
    if (!isEqual(data, prevProps.data)) {
      this.setState({
        data: data,
      });
    }
    if (search !== prevState.search) {
      const newData = searchFunction(search, data);
      this.setState({
        data: newData,
        page: 1,
      });
    }
    if (search === prevState.search && !isEqual(data, prevProps.data)) {
      this.setState({
        search: "",
        page: 1,
      });
    }
    if (selected) {
      document
        .querySelector(`[data-rbd-draggable-id='${selected}']`)
        ?.classList.add("selected");
    }
  }

  render() {
    const {
      search,
      headers,
      pagination,
      amountInTable,
      t,
      style,
      select,
      draggable,
    } = this.props;
    const { sortTable, page, data } = this.state;
    const renderItem = getRenderItem(data, headers, select, this.onClickEffect);

    return (
      <div className="SecondaryTable" style={style}>
        <div className="ContentTable">
          <div className="Header">
            {headers?.map((header, idx) => (
              <Fragment key={`${header.name}-${idx}`}>
                {header.hide && (
                  <div
                    style={{
                      "--width": `${header.width || ""}`,
                      justifyContent: header.justifyContent,
                    }}
                  ></div>
                )}
                {!header.hide && (
                  <div
                    style={{
                      "--width": `${header.width || ""}`,
                      justifyContent: header.justifyContent,
                    }}
                  >
                    <div onClick={() => this.onChangeSort(header)}>
                      <b>{header?.name}</b>
                      {header.sort && (
                        <IconButton
                          className={`SortButton ${
                            sortTable.find((e) => e.key === header.key)?.sort
                          } ${
                            sortTable.find((e) => e.key === header.key)
                              ?.selected
                              ? "selected"
                              : ""
                          }`}
                          size="small"
                        >
                          <Icon fontSize="small">arrow_upward</Icon>
                        </IconButton>
                      )}
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
          <div Data-Body>
            {draggable && (
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" renderClone={renderItem}>
                  {(provided) => (
                    <div
                      className="DraggableArea"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {data?.map((item, index) => (
                        <Draggable
                          key={item.uuid}
                          draggableId={item.uuid}
                          index={index}
                        >
                          {renderItem}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            {!draggable &&
              data
                ?.slice(
                  page * amountInTable - amountInTable,
                  page * amountInTable
                )
                ?.map((data, index) => (
                  <div
                    key={`ContentTable-${index}`}
                    onClick={(e) => this.onClickEffect(e, data)}
                    className={`Data ${select ? "select" : ""} ${get(
                      data,
                      "type",
                      " "
                    )} ${get(data, "isNotActive", "") ? "isNotActive" : ""}`}
                  >
                    {headers?.map((header, idx) => (
                      <span
                        className="Record"
                        key={`${data[header.key]}-${idx}`}
                        style={{
                          "--width": `${header.width || ""}`,
                          justifyContent: header.justifyContent,
                          margin: Boolean(header.verticalCenter)
                            ? "auto 0"
                            : "",
                        }}
                      >
                        {header.chip && (
                          <>
                            {" "}
                            {get(data[header.key], "font") &&
                            get(data[header.key], "background") ? (
                              <BMXChip
                                size={"small"}
                                label={get(data[header.key], "entryName", "-")}
                                font={get(data[header.key], "font")}
                                backgroundColor={get(
                                  data[header.key],
                                  "background"
                                )}
                              />
                            ) : (
                              <span className="DataItem">
                                {get(data[header.key], "entryName", "-")}
                              </span>
                            )}
                          </>
                        )}
                        {!header.chip && (
                          <span
                            style={{ "--numberOfLines": header.numberOfLines }}
                            className={`DataItem ${
                              header.numberOfLines ? "text-lines-clamp" : ""
                            }`}
                          >
                            {data[header.key]}
                          </span>
                        )}
                      </span>
                    ))}
                  </div>
                ))}
          </div>
        </div>
        {search && (
          <div className="SearchBarArea">
            <BMXSearchField
              placeholder={`${t("global:search")}`}
              onChange={this.onChange}
              disableSearchFieldsIcon
              disableResetButton
              search={this.state.search}
            />
          </div>
        )}
        {pagination && (
          <div className="PaginationTable">
            <Pagination
              showFirstButton
              showLastButton
              page={page}
              onChange={this.onChangePage}
              count={Math.ceil((data?.length || 1) / amountInTable) || 1}
            />
          </div>
        )}
      </div>
    );
  }
}

export default compose(withTranslation())(SecondaryTable);

SecondaryTable.propTypes = {
  search: PropTypes.bool,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.bool,
  amountInTable: PropTypes.number,
  sortChange: PropTypes.func,
  draggable: PropTypes.func,
  selected: PropTypes.string,
  select: PropTypes.func,
  searchFunction: PropTypes.func,
  onClickRow: PropTypes.func,
};
SecondaryTable.defaultProps = {
  search: true,
  pagination: true,
  amountInTable: 9,
  searchFunction: () => {},
};
