import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './DoubleLine.scss';
class DoubleLine extends Component {
  render() {
    const { firstLine, secondLine, style} = this.props
    return (
      <div className="doubleLine" style={style}>
        <span>{firstLine}</span>
        <span className="grey text">{secondLine}</span>
      </div>
    );
  }
}
DoubleLine.propTypes  = {
    /**
   pierwsaza linia tekstu
  */
  firstLine: PropTypes.string,
    /**
   Druga linia tekstu
  */
  secondLine: PropTypes.string,
};
export default DoubleLine;
