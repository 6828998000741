import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import { show } from "redux-modal";
import { useDispatch } from "react-redux";

const OfferFormStepOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [offerTypeSelect, setOfferTypeSelect] = useState([]);
  const [customerTypeSelect, setCustomerTypeSelect] = useState([]);

  // NEW COMPONENET
  const [contacts, setContacts] = useState([]);
  const REGISTRY_NAME = "contact";
  const loadContacts = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);
    setContacts(resContact?.payload?.data);
  };
  const { values } = props;

  const getSelectedContact = (contact) => {
    if (!contact) return null;
    return contacts.find(
      (el) => el.uuid === contact.value || el.uuid === contact.uuid
    );
  };

  useEffect(() => {
    loadContacts();

    // LOADING DICTIONARIS
    const fetchData = async () => {
      const offerTypeFetch = GlobalAuthFetch({
        path: "/dictionary/offerType/fieldName",
      });
      const customerTypeFetch = GlobalAuthFetch({
        path: "/dictionary/customerType/fieldName",
      });

      const [offerTypePayload, customerTypePayload] = await Promise.all([
        offerTypeFetch,
        customerTypeFetch,
      ]);
      setOfferTypeSelect(offerTypePayload?.payload?.data?.dictionaryEntries);
      setCustomerTypeSelect(
        customerTypePayload?.payload?.data?.dictionaryEntries
      );
    };
    fetchData();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:offer.basicInformation")} />
      <Row>
        <Col xs={6}>
          <Field
            component={BMXTextField}
            label={`${t("global:offer.title")}*`}
            name="title"
            data-cy="title"
          />
        </Col>
        <Col xs={{ span: 4, offset: 2 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:offer.expirationDate")}*`}
            name="expirationDate"
            data-cy="expirationDate"
          />
        </Col>
        <Col xs={6}>
          <Field
            data-cy="contact"
            component={BMXSelectField}
            values={contacts?.map((el) => el)}
            label={`${t("global:offer.contact.contact")}*`}
            name="contact"
          />
        </Col>
        <Col xs={{ span: 6 }}>
          <Field
            data-cy="representative"
            beforeIcon="people"
            afterIcon="add"
            afterIconSize="18px"
            afterIconClick={() => {
              const contactUuid =
                values?.contact?.uuid || values?.contact?.value;
              const updateSingleRegistry = () =>
                updateDashboardByName(REGISTRY_NAME, contactUuid);
              dispatch(
                show("RepresentativesModal", {
                  loadData: loadContacts,
                  header: t("global:oofer.representativeAdd"),
                  updateSingleRegistry,
                  uuid: contactUuid,
                })
              );
            }}
            disabled={!values?.contact}
            component={BMXSelectField}
            values={getSelectedContact(values?.contact)?.representatives?.map(
              (el) => ({
                value: el.uuid,
                name: `${el.name || ""} ${el.lastname || ""}`,
              })
            )}
            label={`${t("global:offer.representative")}`}
            name="representative"
            renderValue={(el) => {
              return `${el.name || ""} ${el.lastname || ""}`;
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={offerTypeSelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={`${t("global:offer.offerType")}*`}
            name="offerType"
            data-cy="offerType"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={BMXSelectField}
            values={customerTypeSelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={`${t("global:offer.customerType")}*`}
            name="customerType"
            data-cy="customerType"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            component={BMXTextField}
            label={`${t("global:offer.comments")}*`}
            name="comments"
            data-cy="comments"
          />
        </Col>
      </Row>
    </div>
  );
};

export default OfferFormStepOne;
