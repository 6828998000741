import {
  Autocomplete,
  FormHelperText,
  TextField,
  IconButton,
  Icon,
} from "@mui/material";
import { t } from "i18next";
import { get, isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import "./BMXAutocomplate.scss";
export default function BMXAutocomplente(props) {
  const {
    meta: { touched, error, submitError },
    input,
    currentValues,
    afterIcon,
    afterIconSize,
    afterIconClick,
    disabled,
  } = props;

  const prevCurrentValues = useRef();

  const initDefault = input.multiple ? [] : null;

  const [defaultVal, setDefaultVal] = useState(initDefault);

  // eslint-disable-next-line
  useEffect(() => {    
    if(input.multiple)
      return
    if (
      !isEqual(
        get(currentValues, input?.name),
        get(prevCurrentValues, input?.name)
      )
    ) {
      setDefaultVal(get(currentValues, input?.name));
    }
    prevCurrentValues.current = currentValues;
  });

  const isError = () => {
    return !!((error || submitError) && touched);
  };

  const onChange = (event, option) => {
    if(input.multiple){
      setDefaultVal(option);
      return input.onChange(option);
    }
    return input.onChange(option)
  }

  return (
    <>
      <Autocomplete
        data-cy="autocomplete-select"
        {...props}
        multiple={input?.multiple}
        name={input?.name}
        className={`BMXAutocomplate ${input.multiple ? "Multiple" : ""}`}
        value={defaultVal}
        disableCloseOnSelect={Boolean(input.multiple)}
        isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
        onChange={onChange}
        noOptionsText={t("global:no_values")}
        renderInput={(params) => (
          <div className="InputArea">
            <TextField
              variant="standard"          
              {...params}
              {...props}
              {...input}
              error={isError()}
            />
            {isError() && (
              <FormHelperText>
                <span className="error text">{error || submitError}</span>
              </FormHelperText>
            )}
            {afterIcon && (
              <IconButton
                size="small"
                disabled={disabled}
                onClick={afterIconClick}
                disableRipple={!afterIconClick}
              >
                <Icon style={{ fontSize: afterIconSize }}>{afterIcon}</Icon>
              </IconButton>
            )}
          </div>
        )}
      />
    </>
  );
}
