import { useTranslation } from "react-i18next";
import BMXSecretaryModal from "../../../../Components/Modals/BMXSecretaryModal/BMXSecretaryModal";
import { connectModal } from "redux-modal";
import BMXButton from "../../../../Components/Buttons/BMXButton/BMXButton";
import SecretariatCorespondenceContactForm from "../../Forms/SecretariatCorespondenceContactForm/SecretariatCorespondenceContactForm";

const formName = "SecretariatContactForm";

const SecretariatContactModal = (props) => {
  const { handleHide, show } = props;
  const { t } = useTranslation();

  const buttons = () => {
    return (
      <>
        <BMXButton
          type="subbmit"
          data-cy="saveAlert"
          bmxclass="Success"
          form={formName}
        >
          {t("global:save")}
        </BMXButton>
      </>
    );
  };

  return (
    <BMXSecretaryModal
      open={show}
      width={"446px"}
      height={"296px"}
      onClose={handleHide}
      buttons={buttons()}
      header={t("global:secretaryContactModalHeader")}
    > 
        <SecretariatCorespondenceContactForm {...props} formName={formName} />
    </BMXSecretaryModal>
  );
};

export default connectModal({ name: "SecretariatContactModal" })(
  SecretariatContactModal
);
