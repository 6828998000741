import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./AddExistingLaminFormSubmit";
import { useEffect, useState } from "react";
import AddExistingLaminFormStepOne from "./AddExistingLaminFormSteps/AddExistingLaminFormStepOne.js";

const AddExistingLaminForm = (props) => {
  const { update, initialValues: _initialValues } = props;
  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    if (update) {
      setInitialValues({
        ..._initialValues,
      });
      return;
    }

    setInitialValues({});
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="AddExistingLaminForm"
          >
            <AddExistingLaminFormStepOne
              onSubmit={(val) => onSubmit(val, props)}
              {...formProps}
              {...props}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default AddExistingLaminForm;
