import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import { createFilterOptions } from "@mui/material";

const FixedAssetsFormStepOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = createFilterOptions();
  const [contacts, setContacts] = useState([]);
  const [fixedAssetsNameSelect, setfixedAssetsNameSelect] = useState([]);
  const [fixedAssetsTypeSelect, setfixedAssetsTypeSelect] = useState([]);

  const [producer, setProducer] = useState([]);
  const [model, setModel] = useState([]);

  const loadSelectData = async () => {
    const modelFetch = GlobalAuthFetch({ path: "/model" });
    const producerFetch = GlobalAuthFetch({ path: "/producer" });

    const { 0: modelPayload, 1: producerPayload } = await Promise.all([
      modelFetch,
      producerFetch,
    ]);

    if (modelPayload?.payload) {
      setModel(
        modelPayload?.payload?.data?.map((el) => ({
          label: el.value,
          uuid: el.uuid,
        }))
      );
    }
    if (producerPayload?.payload) {
      setProducer(
        producerPayload?.payload?.data?.map((el) => ({
          label: el.value,
          uuid: el.uuid,
        }))
      );
    }
  };

  const { form, values } = props;
  useEffect(() => {
    const fetchData = async () => {
      const fixedAssetsNameFetch = GlobalAuthFetch({
        path: "/dictionary/fixedAssetsName/fieldName",
      });
      const fixedAssetsTypeFetch = GlobalAuthFetch({
        path: "/dictionary/fixedAssetsType/fieldName",
      });

      const [fixedAssetsNamePayload, fixedAssetsTypePayload] =
        await Promise.all([fixedAssetsNameFetch, fixedAssetsTypeFetch]);
      setfixedAssetsNameSelect(
        fixedAssetsNamePayload?.payload?.data?.dictionaryEntries
      );
      setfixedAssetsTypeSelect(
        fixedAssetsTypePayload?.payload?.data?.dictionaryEntries
      );
    };
    fetchData();
  }, []);

  const loadData = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);

    setContacts(resContact?.payload?.data);
  };

  useEffect(() => {
    loadData();
    loadSelectData();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <Row>
        <Col xs={8}>
          <BMXSeparator title={t("global:fixedAssets.newFixedAssets")} />
        </Col>
        <Col xs={8}>
          <Row>
            <Col xs={6}>
              <Field
                component={BMXSelectField}
                values={fixedAssetsNameSelect?.map((el) => ({
                  name: el.entryName,
                  value: el.uuid,
                }))}
                label={t("global:fixedAssets.fixedAssetsName")}
                name="fixedAssetsName"
                data-cy="fixedAssetsName"
              />
            </Col>
            <Col xs={6}>
              <Field
                name="extendedName"
                label={`${t("global:fixedAssets.extendedName")}`}
                component={BMXTextField}
                data-cy="extendedName"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Field
                component={BMXAutocomplente}
                label={`${t("global:fixedAssets.producer")}*`}
                name="producer"
                data-cy="producer"
                options={producer}
                currentValues={values}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  if (filtered?.length <= 0)
                    form.change("producer", inputValue);
                  return filtered;
                }}
              />
            </Col>
            <Col xs={4}>
              <Field
                component={BMXAutocomplente}
                label={`${t("global:fixedAssets.model")}*`}
                name="model"
                data-cy="model"
                options={model}
                currentValues={values}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  if (filtered?.length <= 0) form.change("model", inputValue);
                  return filtered;
                }}
              />
            </Col>
            <Col xs={4}>
              <Field
                name="sn"
                label={`${t("global:fixedAssets.sn")}`}
                component={BMXTextField}
                data-cy="sn"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Field
                component={BMXSelectField}
                values={fixedAssetsTypeSelect?.map((el) => ({
                  name: el.entryName,
                  value: el.uuid,
                }))}
                label={t("global:fixedAssets.fixedAssetsType")}
                name="fixedAssetsType"
                data-cy="fixedAssetsType"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ marginTop: "13px" }}>
              <Field
                data-cy="contact"
                style={{ width: "92.57%" }}
                component={BMXSelectField}
                values={contacts.map((el) => ({
                  value: el.uuid,
                  name: `${el.name}`,
                }))}
                label={`${t("global:fixedAssets.contact.name")}`}
                name="contact"
                afterIcon="add"
                afterIconSize="13px"
                afterIconClick={() => {
                  const updateTableRegistry = () =>
                    updateDashboardByName("contact");
                  dispatch(
                    show("ContactsModal", {
                      updateTableRegistry,
                      header: t("global:contacts.add"),
                      loadData,
                    })
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Field
                name="dateOfPurchase"
                label={`${t("global:fixedAssets.dateOfPurchase")}`}
                component={BMXDatePicker}
                data-cy="dateOfPurchase"
              />
            </Col>
            <Col xs={4}>
              <Field
                name="invoice"
                label={`${t("global:fixedAssets.invoice")}`}
                component={BMXTextField}
                data-cy="invoice"
              />
            </Col>
            <Col xs={4}>
              <Field
                name="guarantee"
                label={`${t("global:fixedAssets.guarantee")}*`}
                component={BMXDatePicker}
                data-cy="guarantee"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={BMXTextField}
                label={`${t("global:iso.comments")}`}
                name="comments"
                data-cy="comments"
                multiline={true}
                maxRows={5}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={12}>
              <Field
                component={BMXDatePicker}
                label={`${t("global:fixedAssets.nextCheckDate")}*`}
                name="nextCheckDate"
                data-cy="nextCheckDate"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={BMXTextField}
                label={`${t("global:fixedAssets.machineCard")}`}
                name="machineCard"
                data-cy="machineCard"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={BMXTextField}
                label={`${t("global:fixedAssets.controlCard")}`}
                name="controlCard"
                data-cy="controlCard"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={BMXTextField}
                label={`${t("global:fixedAssets.inventoryNumber")}`}
                name="inventoryNumber"
                data-cy="inventoryNumber"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FixedAssetsFormStepOne;
