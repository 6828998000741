import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";

const DrawingFormStepOne = (props) => {
  const { t } = useTranslation();

  const [projectTypeSelect, setProjectTypeSelect] = useState([]);

  // NEW COMPONENET
  const [contacts, setContacts] = useState([]);

  const loadContacts = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const [resContact] = await Promise.all([fetchContact]);
    setContacts(resContact?.payload?.data);
  };

  useEffect(() => {
    loadContacts();

    // LOADING DICTIONARIS
    const fetchData = async () => {
      // IMPORT DICTIONARIES IF IT NECESSARY
      const projectTypeFetch = GlobalAuthFetch({
        path: "/dictionary/projectType/fieldName",
      });
      const [projectTypePayload] = await Promise.all([projectTypeFetch]);
      setProjectTypeSelect(
        projectTypePayload?.payload?.data?.dictionaryEntries
      );
    };

    fetchData();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <div>
        <Row>
          <Col xs={8}>
            <BMXSeparator title={t("global:drawing.basicInformation")} />
          </Col>
        </Row>

        <Row>
          <Col xs={8}>
            <Field
              component={BMXSelectField}
              values={projectTypeSelect?.map((el) => ({
                name: el.entryName,
                value: el.uuid,
              }))}
              label={`${t("global:drawing.projectType")}*`}
              name="projectType"
              data-cy="projectType"
            />
          </Col>

          <Col xs={4}>
            <Field
              component={BMXDatePicker}
              label={`${t("global:drawing.dateOfReceipt")}*`}
              name="dateOfReceipt"
              data-cy="dateOfReceipt"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Field
              component={BMXTextField}
              label={`${t("global:drawing.elementName")}*`}
              name="elementName"
              data-cy="elementName"
            />
          </Col>
          <Col xs={{ span: 4, offset: 4 }}>
            <Field
              data-cy="contact"
              component={BMXSelectField}
              values={contacts?.map((el) => el)}
              label={`${t("global:drawing.contact")}`}
              name="contact"
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Field
              component={BMXTextField}
              label={`${t("global:drawing.drawingNumber")}*`}
              name="drawingNumber"
              data-cy="drawingNumber"
            />
          </Col>
          <Col xs={4}>
            <Field
              component={BMXTextField}
              label={`${t("global:drawing.drawingId")}`}
              name="drawingId"
              data-cy="drawingId"
            />
          </Col>
          <Col xs={4}>
            <Field
              component={BMXTextField}
              label={`${t("global:drawing.additionalDescription")}`}
              name="additionalDescription"
              data-cy="additionalDescription"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Field
              component={BMXTextField}
              label={`${t("global:drawing.fileNumber")}`}
              name="fileNumber"
              data-cy="fileNumber"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Field
              component={BMXTextField}
              label={`${t("global:drawing.comments")}`}
              name="comments"
              data-cy="comments"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DrawingFormStepOne;
