import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";

const CarFormStepTwo = (props) => {
  const { t } = useTranslation();
  const [propertySelect, setPropertySelect] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const propertyFetch = GlobalAuthFetch({
        path: "/dictionary/property/fieldName",
      });

      const [propertyPayload] = await Promise.all([propertyFetch]);

      setPropertySelect(propertyPayload?.payload?.data?.dictionaryEntries);
    };
    fetchData();
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:car.carDetails")} />
      <Row>
        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:car.dateOfFirstRegistration")}*`}
            name="dateOfFirstRegistration"
            data-cy="dateOfFirstRegistration"
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:car.insuranceUpTo")}*`}
            name="insuranceUpTo"
            data-cy="insuranceUpTo"
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:car.dateOfTheNextInspection")}*`}
            name="dateOfTheNextInspection"
            data-cy="dateOfTheNextInspection"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:car.warrantyUntil")}*`}
            name="warrantyUntil"
            data-cy="warrantyUntil"
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            component={BMXDatePicker}
            label={`${t("global:car.yearOfProduction")}*`}
            name="yearOfProduction"
            data-cy="yearOfProduction"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <Field
            name="tireSize"
            label={`${t("global:car.tireSize")}`}
            type="number"
            component={BMXTextField}
            data-cy="tireSize"
          />
        </Col>
        <Col xs={4}>
          <Field
            component={BMXSelectField}
            values={propertySelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={`${t("global:car.property")}`}
            name="property"
            data-cy="property"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            name="description"
            label={`${t("global:car.description")}`}
            component={BMXTextField}
            data-cy="description"
            maxRows={4}
            multiline={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CarFormStepTwo;
