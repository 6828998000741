import { useEffect, useState } from "react";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import "./ListOfDrawingsList.scss";
import {
  Icon,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ListOfDrawingsList = (props) => {
  const { t } = useTranslation();
  const { updateReg } = props;
  const [projectTypes, setProjectTypes] = useState([]);
  // TABLE SETUP
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "lp", label: t("global:drawing.lp"), minWidth: 70 },
    {
      id: "entryName",
      label: t("global:drawing.projectType"),
      minWidth: 370,
    },
    { id: "button", label: "", minWidth: 70, align: "right" },
  ];

  const loadData = async () => {
    const projectTypeFetch = GlobalAuthFetch({
      path: "/dictionary/projectType/fieldName",
    });
    const [projectTypePayload] = await Promise.all([projectTypeFetch]);
    setProjectTypes(projectTypePayload?.payload?.data?.dictionaryEntries);
  };

  useEffect(() => {
    loadData();
  }, []);

  const searchByProjectName = (projectName) => {
    updateReg({
      selectedSearchFields: ["projectType.entryName"],
      filterSearch: projectName.entryName,
      search: projectName.entryName,
    });
  };

  return (
    <>
      <div className={props.formName}>
        <TableContainer sx={{ maxHeight: 475 }}>
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {projectTypes &&
                projectTypes
                  .sort((a, b) => a.entryName.localeCompare(b.entryName))
                  .map((el, index) => ({
                    lp: index + 1,
                    entryName: el.entryName,
                    value: el.uuid,
                    button: (
                      <IconButton
                        size={"small"}
                        onClick={() => searchByProjectName(el)}
                      >
                        <Icon style={{ fontSize: 20, color: "#19aa8d" }}>
                          arrow_forward
                        </Icon>
                      </IconButton>
                    ),
                  }))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.entryName}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              align={column.align}
                              className="table-cell-text"
                              key={column.id}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} liczba wszystkich projektów: ${count}`
          }
          component="div"
          count={projectTypes?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ListOfDrawingsList;
