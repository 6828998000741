import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { fixedAssetsReducer } = store.getState();

  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  if (values?.fixedAssetsType?.value)
    set(data, "fixedAssetsType.uuid", values?.fixedAssetsType?.value);

  if (values?.fixedAssetsName?.value)
    set(data, "fixedAssetsName.uuid", values?.fixedAssetsName?.value);

  if (values?.contact?.value) {
    set(data, "contact.uuid", values?.contact?.value);
  }

  let payload;
  const finalData = {
    ...data,
    model: null,
    producer: null,
    attachments: [],
  };

  if (
    typeof values?.model === "string" ||
    typeof values?.model?.label === "string"
  ) {
    const {
      payload: { data },
    } = await GlobalAuthFetch({
      path: "/model",
      method: "POST",
      data: {
        value: values?.model?.label || values?.model,
      },
    });

    finalData.model = {
      uuid: data.uuid,
      value: data.value,
    };
  }
  if (
    typeof values?.producer === "string" ||
    typeof values?.producer?.label === "string"
  ) {
    const {
      payload: { data },
    } = await GlobalAuthFetch({
      path: "/producer",
      method: "POST",
      data: {
        value: values?.producer?.label || values?.producer,
      },
    });

    finalData.producer = {
      uuid: data.uuid,
      value: data.value,
    };
  }

  if (!update) {
    finalData.status = fixedAssetsReducer?.availableStatuses.find(
      (el) => !!el.isSelected
    );
    const res = await GlobalAuthFetch({
      path: `/fixedAssets`,
      method: "POST",
      data: finalData,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/fixedAssets/${uuid}`,
      method: "PATCH",
      data: finalData,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.fixedAssetsName) {
    errorsStep0.fixedAssetsName = t(
      "global:fixedAssets.errors.fixedAssetsName"
    );
  }
  if (!values.producer) {
    errorsStep0.producer = t("global:fixedAssets.errors.producer");
  }
  if (!values.model) {
    errorsStep0.model = t("global:fixedAssets.errors.model");
  }
  if (!values.fixedAssetsType) {
    errorsStep0.fixedAssetsType = t(
      "global:fixedAssets.errors.fixedAssetsType"
    );
  }
  if (!values.nextCheckDate) {
    errorsStep0.nextCheckDate = t("global:fixedAssets.errors.nextCheckDate");
  }
  if (!values.inventoryNumber) {
    errorsStep0.inventoryNumber = t(
      "global:fixedAssets.errors.inventoryNumber"
    );
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
