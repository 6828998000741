import i18n from "../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { cloneDeep, get, set } from "lodash";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import store from "../../../../store";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { formManagementReducer } = store.getState();

  const isRequiredElements = values.formManagementElements?.every(
    (element) =>
      Boolean(element.quantity) && Boolean(element.technicalConditions)
  );
  if (!isRequiredElements) {
    props?.setShowError(true);
    return;
  }
  props?.setShowError(false);
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;

  const data = cloneDeep(values);

  if (values?.contact?.value) set(data, "contact.uuid", values?.contact?.value);

  if (values?.reprezentative?.value)
    set(data, "reprezentative.uuid", values?.reprezentative?.value);

  if (values?.worker?.value) set(data, "worker.uuid", values?.worker?.value);

  let payload;

  if (!update) {
    data.status = formManagementReducer?.availableStatuses.find(
      (el) => !!el.isSelected
    );

    set(
      data,
      "formManagementElements",
      get(data, "formManagementElements", [])?.map((el) => ({
        ...el,
        uuid: null,
      }))
    );

    const res = await GlobalAuthFetch({
      path: `/formmanagement`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/formmanagement/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    return;
  }
  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values?.contact) {
    errorsStep0.contact = t("global:errors.formManagement.contact.error");
  }
  if (!values?.documentNumber) {
    errorsStep0.documentNumber = t(
      "global:errors.formManagement.documentNumber.error"
    );
  }
  if (!values?.documentData) {
    errorsStep0.documentData = t(
      "global:errors.formManagement.documentData.error"
    );
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  return { ...errorsStep0 };
};
