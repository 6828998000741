import { t } from "i18next";

export const onSubmit = async (values, props) => {
  const{values:val,form,index,forceUpdate} = props;

  const prevElements = val.formManagementElements;
  if(prevElements && prevElements.length > 0){
    prevElements.at(index).quantity = values.quantity;
    prevElements.at(index).technicalConditions = values.technicalConditions;
    prevElements.at(index).comments = values.comments;
    form.change("formManagementElements",prevElements);
    forceUpdate();
  }
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errors = {};
  if (!values.quantity) {
    errors.quantity = t("global:formManagement.errors.quantity");
  } 
  if (values.quantity <= 0) {
    errors.quantity = t("global:formManagement.errors.quantityNotNull");
  }
  if (!values.technicalConditions) {
    errors.technicalConditions = t("global:formManagement.errors.technicalConditions");
  } 

  return errors;
};
