import React, { useEffect, useState } from "react";
import "./ComplaintFrom.scss";
import PrintDocumentTemplate from "../../../../Components/PrintDocumentTemplate/PrintDocumentTemplate";
import LaminLogo from "../Logo/LaminLogo.png";
import moment from "moment";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";

export const ComplaintFrom = () => {
  const [users, setUsers] = useState([]);

  const loadData = async () => {
    const res = await GlobalAuthFetch({ path: "/representative/all" });
    if (!res.payload) {
      return;
    }
    setUsers(
      res?.payload?.data?.map((user) => ({
        label: `${user?.name || ""} ${user?.lastname} ${user?.email}`,
        uuid: user?.email,
      }))
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const printData = JSON.parse(sessionStorage.getItem("printData"));

  return (
    <PrintDocumentTemplate
      fileName={`Reklamacja do ${printData?.contractor?.name || "dostawcy"}`}
      usersToSendEmail={users}
    >
      <div className="TargetPrintAreaComplaintFrom">
        <div className="HeaderArea">
          <div className="LogoArea">
            <img className="LaminLogo" alt="LaminLogo" src={LaminLogo} />
            <span>Szelengowski Szwarc Sp. Jawna</span>
            <span>Wierzchucin Królewski 77,</span>
            <span>86-017 Wierzchucin Królewski</span>
          </div>
          <span className="DateArea">
            <span>
              <h5>FOR 30</h5>
              <span>wyd. 03</span>
              <span>z dn. 04.07.2022</span>
            </span>
            <span>
              Wierzchucin Królewski dnia{" "}
              {moment(printData?.dateOfCompletionOfTheComplaint).format(
                "DD-MM-YYYYr"
              )}
            </span>
          </span>
        </div>
        <div className="HeaderEnd">
          {" "}
          <span className="InnerText">REKLAMACJA DO DOSTAWCY</span>
        </div>
        <div className="ComplaintInfoArea">
          <div>
            <div>
              <span>Index reklamacji:</span>
              <span>{printData.index || " "}</span>
            </div>
            <div>
              <span>Przedmiot reklamacji:</span>
              <span>{printData.subjectOfTheComplaint || " "}</span>
            </div>
            <span>
              Ilość reklamowana: {printData.numberOfComplaints || " "}
            </span>
            <span>
              Numer partii lub zamówienia: {printData.lotNumber || " "}
            </span>
            <span>Ilość reklamowana: {printData.invoiceNumber || " "}</span>
            <div></div>
            <span>
              Typ reklamacji: {printData.complaintType.entryName || " "}
            </span>
            <span>
              Wykryto w czasie: {printData.detectedInTime.entryName || " "}
            </span>
          </div>
          <div>
            <div>
              <span>
                Dane dostawcy: {printData.contractor.contactId || " "}
              </span>
              <span>{printData.contractor.name || " "}</span>
              <span>
                ul. {printData?.contractor?.street || ""}{" "}
                {printData?.contractor?.houseNumber || ""}{" "}
                {printData?.contractor?.postCode || ""}{" "}
                {printData?.contractor?.post || ""}
              </span>
              <span>NIP: {printData?.contractor?.nip || " "}</span>
            </div>
          </div>
        </div>
        <div className="DescriptionPrintArea">
          <div>
            <div>
              <span>Opis niezgodności</span>
            </div>
            <div>{printData.descriptionOfNonCompliance || ""}</div>
          </div>
          <div>
            <div>
              <span>Oczekiwania reklamującego</span>
            </div>
            <div>{printData.advertiserExpectations || ""}</div>
          </div>
          <div>
            <div>
              <span>Uwagi</span>
            </div>
            <div>{printData.comments || ""}</div>
          </div>
        </div>
        <div className="SignArea">
          <span>Spec. ds. jakości</span>
          <span>........................................................</span>
        </div>
        <span className="Footer">www.lamin.pl</span>
      </div>
      <div className="TargetPrintAreaComplaintFrom">
        <div className="HeaderArea">
          <div className="LogoArea">
            <img className="LaminLogo" alt="LaminLogo" src={LaminLogo} />
            <span>Szelengowski Szwarc Sp. Jawna</span>
            <span>Wierzchucin Królewski 77,</span>
            <span>86-017 Wierzchucin Królewski</span>
          </div>
          <span className="DateArea">
            <span>
              <h5>FOR 30</h5>
              <span>wyd. 03</span>
              <span>z dn. 04.07.2022</span>
            </span>
            <span>
              Wierzchucin Królewski dnia{" "}
              {moment(printData?.dateOfCompletionOfTheComplaint).format(
                "DD-MM-YYYYr"
              )}
            </span>
          </span>
        </div>
        <div className="HeaderEnd">
          {" "}
          <span className="InnerText">REKLAMACJA DO DOSTAWCY</span>
        </div>
        <div className="ComplaintInfoArea2">
          <div>
            <div>
              <span>Index reklamacji:</span>
              <span>{printData.index || " "}</span>
            </div>
            <div>
              <span>Przedmiot reklamacji:</span>
              <li className="ms-2">
                nieuwzględniona - reklamacja bezzasadna / uszkodzenie
                mechaniczne / usterka niewykryta
              </li>
              <li className="ms-2">
                uwzględniona - naprawa / wymiana na wolne od wad / dostarczenie
                braków{" "}
              </li>
            </div>
            <span>Data przyjazdu serwisu (rzeczywista data rozpoczęcia):</span>
            <span>Data usunięcia usterki (rzeczywista data zakończenia):</span>
          </div>
        </div>
        <div className="DescriptionPrintArea">
          <div>
            <div>
              <span>Opis naprawy (podjęte działania)</span>
            </div>
            <div></div>
          </div>
          <div>
            <div>
              <span>
                Działania zapobiegawcze podjęte przez dostawce (przyczyna
                niezgodności)
              </span>
            </div>
            <div></div>
          </div>
          <div>
            <div>
              <span>Uwagi</span>
            </div>
            <div></div>
          </div>
        </div>
        <div className="DatePrintArea">
          <span>
            ................................................, dn.
            ..............................
          </span>
        </div>
        <div className="SignArea">
          <span>Spec. ds. jakości</span>
          <span>........................................................</span>
        </div>
        <span className="Footer">www.lamin.pl</span>
      </div>
    </PrintDocumentTemplate>
  );
};
