import i18n from "../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";
const { t } = i18n;
export const onSubmit = async (values, props) => {
  const { updateTableRegistry, loadData, updateSingleRegistry } = props;
  const data = cloneDeep(values);
  const uuidToUpdate = props?.initialValues?.uuid;

  if (values?.form) {
    set(data, "uuid", values?.form?.uuid);
  }

  let payload;

  const res = await GlobalAuthFetch({
    path: `/form/${uuidToUpdate}/addFormToExisting`,
    method: "POST",
    data,
  });
  payload = res.payload;

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.form) {
    errorsStep0.form = t("global:fieldRequired");
  }

  return { ...errorsStep0 };
};
