import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  availableStatuses: null,
  yearOfPurchase:null,
  before30day:null,
  registryRights: [],
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      { key: "index", name: "global:fixedAssets.index" },
      { key: "producer.value", name: "global:fixedAssets.producer" },
      { key: "model.value", name: "global:fixedAssets.model" },
      {
        key: "fixedAssetsName.entryName",
        name: "global:fixedAssets.fixedAssetsName",
      },
      {
        key: "fixedAssetsType.entryName",
        name: "global:fixedAssets.fixedAssetsType",
      },
      { key: "machineCard", name: "global:fixedAssets.machineCard" },
      { key: "nextCheckDate", name: "global:fixedAssets.nextCheckDate" },
      { key: "status.name", name: "global:fixedAssets.status" },
    ],
    selectedSearchFields: ["index", "producer.value"],
    dictionaryFields: ["fixedAssetsType", "fixedAssetsName"],
    url: "fixedAssets",
  },
};

const fixedAssetsReducer = createSlice({
  name: "fixedAssetsReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = fixedAssetsReducer.actions;

export default fixedAssetsReducer.reducer;
