import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { complaintReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  if (values?.complaintType?.value)
    set(data, "complaintType.uuid", values?.complaintType?.value);

  if (values?.representative?.value) set(data, "representative.uuid", values?.representative?.value);

  if (values?.detectedInTime?.value)
    set(data, "detectedInTime.uuid", values?.detectedInTime?.value);

  if (values?.contractor?.value) {
    set(data, "contractor.uuid", values?.contractor?.value);
  }

  if (values?.responsibleAt?.value) {
    set(data, "responsibleAt.uuid", values?.responsibleAt?.value);
  }
  
  if (values?.fromTo?.value) {
    set(data, "fromTo", values?.fromTo?.value);
  }

  let payload;

  if (!update) {
    data.status = complaintReducer?.availableStatuses.find((el) => !!el.isSelected);

    const res = await GlobalAuthFetch({
      path: `/complaint`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/complaint/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    props.setErrorHeader(1, true);

    return;
  }

  props.setErrorHeader(0, false);
  props.setErrorHeader(1, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};
  const errorsStep1 = {};

  if (!values.contractor) {
    errorsStep0.contractor = t("global:complaint.errors.contractor");
  }
  
  if (!values.responsibleAt) {
    errorsStep0.responsibleAt = t("global:complaint.errors.responsibleAt");
  }
  
  if (!values.representative) {
    errorsStep0.representative = t("global:complaint.errors.representative");
  }
  
  if (!values.complaintNumber) {
    errorsStep0.complaintNumber = t("global:complaint.errors.complaintNumber");
  }
  
  if (!values.subjectOfTheComplaint) {
    errorsStep0.subjectOfTheComplaint = t("global:complaint.errors.subjectOfTheComplaint");
  }

  if (!values.fromTo) {
    errorsStep0.fromTo = t("global:complaint.errors.fromTo");
  }

  if (!values.idOfTheSubjectOfTheComplaint) {
    errorsStep0.idOfTheSubjectOfTheComplaint = t("global:complaint.errors.idOfTheSubjectOfTheComplaint");
  }

  if (!values.numberOfComplaints) {
    errorsStep0.numberOfComplaints = t("global:complaint.errors.numberOfComplaints");
  }

  if (!values.lotNumber) {
    errorsStep0.lotNumber = t("global:complaint.errors.lotNumber");
  }

  if (!values.dateOfComplaints) {
    errorsStep0.dateOfComplaints = t("global:complaint.errors.dateOfComplaints");
  }

  if (!values.complaintType) {
    errorsStep0.complaintType = t("global:complaint.errors.complaintType");
  }

  if (!values.descriptionOfNonCompliance) {
    errorsStep0.descriptionOfNonCompliance = t("global:complaint.errors.descriptionOfNonCompliance");
  }

  if (!values.dateOfCompletionOfTheComplaint) {
    errorsStep0.dateOfCompletionOfTheComplaint = t("global:complaint.errors.dateOfCompletionOfTheComplaint");
  }

  if(!values.drawingId){
    errorsStep0.drawingId = t("global:complaint.errors.drawingId");
  }
 
  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }
  return { ...errorsStep0, ...errorsStep1 };
};
