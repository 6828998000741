import { useTranslation } from "react-i18next";
import { connectModal } from "redux-modal";
import BMXCardModal from "../../../../Components/Modals/BMXCardModal/BMXCardModal";
import PrintDocumentEmailForm from "../../Forms/PrintDocumentEmailForm/PrintDocumentEmailForm";

const PrintDocumentEmailModal = (props) => {
  const { handleHide, show } = props;

  const { t } = useTranslation();

  return (
    <BMXCardModal
      right="10px"
      bottom="4rem"
      width="500px"
      height="200px"
      header={t("global:printDocumentEmailModal.header")}
      open={show}
      onOutside={false}
      onClose={handleHide}
    >
      <PrintDocumentEmailForm {...props}/>
    </BMXCardModal>
  );
};

export default connectModal({ name: "printDocumentEmailModal" })(PrintDocumentEmailModal);
