import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  registryRights: [],
  availableStatuses: null,
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      {
        key: "registrationNumber",
        name: "global:measuringDevice.registrationNumber",
      },
      { key: "equipmentName", name: "global:measuringDevice.equipmentName" },
      { key: "serialNumber", name: "global:measuringDevice.serialNumber" },
      { key: "checkTime", name: "global:measuringDevice.checkTime" },
      { key: "status.name", name: "global:measuringDevice.status" },
    ],
    selectedSearchFields: ["title"],
    dictionaryFields: [],
    url: "measuring",
  },
};

const measuringReducer = createSlice({
  name: "measuringReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = measuringReducer.actions;

export default measuringReducer.reducer;
