import { Card } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BMXPortal from "../../Portal/BMXPortal/BMXPortal";
import PropTypes from "prop-types";
import "./BMXCardModal.scss";

const BMXCardModal = (props) => {
  const {
    children,
    open,
    onClose,
    top,
    left,
    right,
    bottom,
    width,
    height,
    header,
    onOutside,
  } = props;

  return (
    <BMXPortal
      open={open}
      onClose={Boolean(onOutside) ? onClose : () => {}}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
    >
      <Card style={{ width: width, height }}>
        <div>
          <h4>{header}</h4>
          <CloseIcon className="CloseIcon" fontSize="small" onClick={onClose} />
        </div>
        {children}
      </Card>
    </BMXPortal>
  );
};

BMXCardModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  header: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onOutside: PropTypes.bool,
};

BMXCardModal.defaultProps = {
  onOutside: true,
};

export default BMXCardModal;
