import React, { useEffect, useState } from "react";
import "./FormManagement.scss";
import PrintDocumentTemplate from "../../../../Components/PrintDocumentTemplate/PrintDocumentTemplate";
import LaminLogo from "../Logo/LaminLogo.png";
import moment from "moment";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { makeChunkedArray } from "../../../../Utils/Array/Array";

export const FormManagement = () => {
  const [users, setUsers] = useState([]);

  const loadData = async () => {
    const res = await GlobalAuthFetch({ path: "/representative/all" });
    if (!res.payload) {
      return;
    }
    setUsers(
      res?.payload?.data?.map((user) => ({
        label: `${user?.name || ""} ${user?.lastname} ${user?.email}`,
        uuid: user?.email,
      }))
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const printData = JSON.parse(sessionStorage.getItem("printData"));

  const tableChunkedData = makeChunkedArray(
    printData?.formManagementElements,
    5
  );

  const header = [
    { name: "NAZWA FORMY", index: "name", width: "30%" },
    { name: "NUMER FORMY", index: "formId", width: "30%" },
    { name: "UWAGI / INDEKSY", index: "comments", width: "30%" },
    { name: "ILOŚĆ", index: "quantity", width: "10%" },
  ];

  return (
    <PrintDocumentTemplate
      fileName={`Protokół ${
        printData.documentType === "Przyjęcie" ? "odbioru" : "przekazania"
      } formy nr ${printData?.index || ""}`}
      usersToSendEmail={users}
    >
      {tableChunkedData.map((table) => (
        <div className="TargetPrintAreaFromManagement">
          <div className="HeaderArea">
            <div className="LogoArea">
              <img className="LaminLogo" alt="LaminLogo" src={LaminLogo} />
              <span>Szelengowski Szwarc Sp. Jawna</span>
              <span>Wierzchucin Królewski 77,</span>
              <span>86-017 Wierzchucin Królewski</span>
            </div>
            <span className="DateArea">
              <span>
                Wierzchucin Królewski dnia{" "}
                {moment(printData?.dateOfCompletionOfTheComplaint).format(
                  "DD-MM-YYYYr"
                )}
              </span>
            </span>
          </div>
          <div className="HeaderEnd">
            {" "}
            <span className="InnerText">
              PROTOKÓŁ{" "}
              {printData.documentType === "Przyjęcie"
                ? "ODBIORU"
                : "PRZEKAZANIA"}{" "}
              FORMY
            </span>
          </div>
          <div className="FormManagementInfoArea">
            {printData.documentType !== "Przyjęcie" ? (
              <>
                <div>
                  <div>
                    <span>PRZEKAZUJĄCY:</span>
                    <span>Szelengowski Szwarc Sp. Jawna</span>
                    <span>Wierzchucin Królewski 77,</span>
                    <span>86-017 Wierzchucin Królewski</span>
                    {printData?.worker ? (
                      <span>
                        {printData?.worker?.firstName || ""}{" "}
                        {printData?.worker?.lastName || ""}{" "}
                        {printData?.worker?.phone || ""}{" "}
                        {printData?.worker?.mail || " "}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div>
                    <span>POBIERAJĄCY:</span>
                    <span>{printData.contact?.name || " "}</span>
                    <span>
                      ul. {printData?.contact?.street || ""}{" "}
                      {printData?.contact?.houseNumber || ""}{" "}
                      {printData?.contact?.postCode || ""}{" "}
                      {printData?.contact?.post || ""}
                    </span>
                    {printData?.contractor?.nip ? (
                      <span>NIP: {printData?.contractor?.nip || " "}</span>
                    ) : null}

                    {printData?.representative ? (
                      <>
                        <span>
                          {printData?.representative?.name || ""}{" "}
                          {printData?.representative?.lastname || ""}{" "}
                        </span>
                        <span>
                          tel: {printData?.representative?.phoneNumber || ""}{" "}
                        </span>
                        <span>
                          e-mail: {printData?.representative?.email || ""}
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div>
                    <span>PRZEKAZUJĄCY:</span>
                    <span>{printData.contact?.name || " "}</span>
                    <span>
                      ul. {printData?.contact?.street || ""}{" "}
                      {printData?.contact?.houseNumber || ""}{" "}
                      {printData?.contact?.postCode || ""}{" "}
                      {printData?.contact?.post || ""}
                    </span>
                    {printData?.contractor?.nip ? (
                      <span>NIP: {printData?.contractor?.nip || " "}</span>
                    ) : null}

                    {printData?.representative ? (
                      <>
                        <span>
                          {printData?.representative?.name || ""}{" "}
                          {printData?.representative?.lastname || ""}{" "}
                        </span>
                        <span>
                          tel: {printData?.representative?.phoneNumber || ""}{" "}
                        </span>
                        <span>
                          e-mail: {printData?.representative?.email || ""}
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div>
                    <span>POBIERAJĄCY:</span>
                    <span>Szelengowski Szwarc Sp. Jawna</span>
                    <span>Wierzchucin Królewski 77,</span>
                    <span>86-017 Wierzchucin Królewski</span>
                    {printData?.worker ? (
                      <span>
                        {printData?.worker?.firstName || ""}{" "}
                        {printData?.worker?.lastName || ""}{" "}
                        {printData?.worker?.phone || ""}{" "}
                        {printData?.worker?.mail || " "}
                      </span>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
          <h4 className="mt-5 text-center">
            Protokół{" "}
            {printData.documentType === "Przyjęcie" ? "odbioru" : "przekazania"}{" "}
            formy nr: {printData?.index || ""}
          </h4>
          {}
          <h5 className="mt-2 text-center">
            nr dokumentu: {printData?.documentNumber || ""}
          </h5>

          <div className="mt-5 FormManagementList">
            <div className="FormManagementRow">
              {header.map((el) => (
                <div
                  className="FormManagementItem"
                  style={{ minWidth: el?.width, maxWidth: el?.width }}
                >
                  <div className="FormManagementHeader">{el.name}</div>
                </div>
              ))}
            </div>
            {table?.map((data) => (
              <div className="FormManagementRow">
                {header.map((el) => (
                  <div
                    className="FormManagementItem"
                    style={{ minWidth: el?.width, maxWidth: el?.width }}
                  >
                    <div className="FormManagementData">{data[el.index]}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="SginArea">
            <div className="Sign1">
              <span>Przekazujący formy:</span>
              <span>
                <span>
                  ........................................................
                </span>
                <span>Data, podpis</span>
              </span>
            </div>
            <div className="Sign2">
              <span>Pobierający formy:</span>
              <span>
                <span>
                  ........................................................
                </span>
                <span>Data, podpis</span>
              </span>
            </div>
          </div>
          <span className="Footer">www.lamin.pl</span>
        </div>
      ))}
    </PrintDocumentTemplate>
  );
};
