import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./FixedAssetsYearFormSubmit";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXTextField from "../../../../Components/Inputs/BMXTextField/BMXTextField";
import { useState } from "react";

const FixedAssetsYearForm = (props) => {
  const { t } = useTranslation();
  const { formName } = props;
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState(null);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {({ handleSubmit }) => {
        return (
          <form id={formName} onSubmit={handleSubmit}>
            <>
              <Row>
                <Col xs={12}>
                  <Field
                    component={BMXTextField}
                    className="pa-0 ma-0"
                    label={`${t("global:fixedAssets.yearOfPurchase")}*`}
                    name="yearOfPurchase"
                    data-cy="yearOfPurchase"
                  />
                </Col>
              </Row>
            </>
          </form>
        );
      }}
    </Form>
  );
};

export default FixedAssetsYearForm;
