import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manager: null,
  defaultFileStatus: null,
  defaultFolders: null,
  registryRights: [],
  availableStatuses: null,
  table: {
    search: "",
    limit: 11,
    page: 1,
    pages: 1,
    sort: "",
    data: [],
    searchFields: [
      {
        key: "project.entryName",
        name: "global:formLamin.project",
      },
      {
        key: "name",
        name: "global:formLamin.name",
      },
      {
        key: "formId",
        name: "global:formLamin.formId",
      },
      {
        key: "otherId",
        name: "global:formLamin.otherId",
      },
      {
        key: "type.entryName",
        name: "global:formLamin.type",
      },
      {
        key: "status.entryName",
        name: "global:formLamin.status",
      },
    ],
    selectedSearchFields: ["project.entryName"],
    dictionaryFields: ["type"],
    url: "form",
  },
};

const formReducer = createSlice({
  name: "formReducer",
  initialState,
  reducers: {
    setRegistryRights: (state, { payload }) => {
      state.registryRights = payload;
    },
    updateManager: (state, { payload }) => {
      state.manager = payload;
    },
    updateDefaultFileStatus: (state, { payload }) => {
      state.defaultFileStatus = payload;
    },
    updateDefaultFolders: (state, { payload }) => {
      state.defaultFolders = payload;
    },
    updateAvailableStatuses: (state, { payload }) => {
      state.availableStatuses = payload;
    },
    updateRegistry: (state, { payload }) => {
      state.table = { ...state.table, ...payload };
    },
    clearRegistry: () => initialState,
  },
  extraReducers: {},
});

export const {
  updateRegistry,
  clearRegistry,
  updateManager,
  updateDefaultFileStatus,
  updateDefaultFolders,
  updateAvailableStatuses,
  setRegistryRights,
} = formReducer.actions;

export default formReducer.reducer;
