import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { measuringReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  let payload;

  if (!update) {
    data.status = measuringReducer?.availableStatuses.find(
      (el) => !!el.isSelected
    );

    const res = await GlobalAuthFetch({
      path: `/measuring`,
      method: "POST",
      data,
    });

    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/measuring/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.equipmentName) {
    errorsStep0.equipmentName = t("global:fieldRequired");
  }

  if (!values.measurementRange) {
    errorsStep0.measurementRange = t("global:fieldRequired");
  }

  if (!values.registrationNumber) {
    errorsStep0.registrationNumber = t("global:fieldRequired");
  }

  if (!values.serialNumber) {
    errorsStep0.serialNumber = t("global:fieldRequired");
  }

  if (!values.checkTime) {
    errorsStep0.checkTime = t("global:fieldRequired");
  }

  if (!values.checkTime) {
    errorsStep0.checkTime = t("global:fieldRequired");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
