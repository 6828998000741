import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { formReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  delete data.drawing;

  if (values?.contact?.value) set(data, "contact.uuid", values?.contact?.value);

  if (values?.project?.value) {
    set(data, "projects.uuid", values?.projects);
  }

  if (values?.type?.value) {
    set(data, "type.uuid", values?.type?.value);
  }

  let payload;

  if (!update) {
    data.status = formReducer?.availableStatuses.find((el) => !!el.isSelected);

    const res = await GlobalAuthFetch({
      path: `/form`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/form/${uuid}`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  // const { update } = props;
  const errorsStep0 = {};

  if (!values.drawings || values.drawings?.length <= 0) {
    errorsStep0.drawing = t("global:fieldRequired");
  }

  if (!values.formId) {
    errorsStep0.formId = t("global:fieldRequired");
  }

  if (!values.name) {
    errorsStep0.name = t("global:fieldRequired");
  }

  if (!values.contact) {
    errorsStep0.contact = t("global:fieldRequired");
  }

  if (!values.projects || values.projects?.length <= 0) {
    errorsStep0.project = t("global:fieldRequired");
  }

  if (!values.type) {
    errorsStep0.type = t("global:fieldRequired");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
