import { Fab } from "@mui/material";
import "./BMXButton.scss";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { LoadingButton } from "@mui/lab";
import { forwardRef } from "react";

const BMXButton = forwardRef((props, ref) => {
  const { children, bmxclass, buttontype, size, disabled, tooltip } = props;
  return (
    <>
      {buttontype === "Button" && (
        <LoadingButton
          size={size}
          ref={ref}
          className={`BMXButton ${bmxclass ? bmxclass : ""} ${
            disabled ? "Disabled" : ""
          }`}
          {...props}
        >
          {children}
        </LoadingButton>
      )}
      {buttontype === "Fab" && (
        <Tooltip title={!tooltip ? "" : tooltip}>
          <Fab
            size={size}
            ref={ref}
            className={`BMXFab ${bmxclass}`}  
            {...props}          
          >
            {children}
          </Fab>
        </Tooltip>
      )}
    </>
  );
});

BMXButton.defaultProps = {
  buttontype: "Button",
  size: "small",
};
BMXButton.propTypes = {
  /**
     Props określający typ przycisku || Fab || Button
    */
  buttontype: PropTypes.string.isRequired,
  /**
     Props określający rozmiar przycisku || small || medium || large
    */
  size: PropTypes.string,
  /**
     Props określający tooltip który ma się wyświetlić po najechaniu na przycisk (uwaga pamiętać o wersjach językowych {useTranslation})
    */
  tooltip: PropTypes.string,
  /**
     Props przypisujący klasę css do przycisku 
    */
  bmxclass: PropTypes.string,
  /**
     Props określający czy przycisk ma być aktywny lub nie
    */
  disabled: PropTypes.bool,
  /**
    Props renderuje opakowane elementy 
   */
  children: PropTypes.node,
};

export default BMXButton;
