import { Modal } from "@mui/material";
import BMXButton from "../../Buttons/BMXButton/BMXButton";
import "./BMXConfirmModal.scss";
import PropTypes from "prop-types";
import React,{useRef} from "react";
import { useTranslation } from 'react-i18next';
const BMXConfirmModal = (props) => {
  const {
    open,
    onClose,
    children,
    buttons,    
    width,
    height
  } = props;

  const myModal = useRef();
  const { t } = useTranslation();
  const closeModal = () => {
    const BMXConfirmModal = myModal.current;
    BMXConfirmModal?.classList.add("fadeOut");
    const timer = setTimeout(() => {
      onClose();
    }, 220);
    return () => clearTimeout(timer);
  };
  return (
    <Modal open={open} onClose={closeModal} disableEnforceFocus>
      <div
        ref={myModal}
        className={`BMXConfirmModal ${open ? "fadeIn" : "fadeOut"}`}
        style={{ minWidth: width, }}
      >
        
        <div className="Body" style={{minHeight:height} }>            
            {children}
        </div>
        <div className="Buttons">
          <div>
            <BMXButton className="darkGrey background"onClick={closeModal}>{t('global:cancel')}</BMXButton>
            {buttons}
          </div>
        </div>
      </div>
    </Modal>
  );
};
BMXConfirmModal.defaultProps ={
  onClose: () => { },  
}
BMXConfirmModal.propTypes = {  
   /**
   Props zawiera użytkowników aplikacji 
  */ 
  children: PropTypes.node,
   /**
   Props zawiera użytkowników aplikacji 
  */ 
  onClose: PropTypes.func,
   /**
   Props zawiera użytkowników aplikacji 
  */ 
  open: PropTypes.bool, 
   /**
   Props zawiera użytkowników aplikacji 
  */ 
  buttons : PropTypes.func, 
   /**
   Props zawiera użytkowników aplikacji 
  */ 
  width : PropTypes.string, 
   /**
   Props zawiera użytkowników aplikacji 
  */ 
  height : PropTypes.string, 
};

export default BMXConfirmModal;
