import BMXCardModal from "../../../../Components/Modals/BMXCardModal/BMXCardModal";
import { connectModal } from "redux-modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddExistingLaminForm from "../../Forms/AddExistingLaminForm/AddExistingLaminForm";

const AddExistingFormModal = (props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { title: t("global:iso.basicInformation"), error: false },
  ]);
  
  const formName = "AddExistingLaminForm";
  const { handleHide, show, clientX, clientY } = props;

  const setErrorHeader = (formNumber, val) => {
    steps[formNumber].error = val;
    setSteps([...steps]);
  };

  return (
    <BMXCardModal
      activeStep={step}
      setStep={setStep}
      open={show}
      left={clientX}
      top={clientY}
      height="max-content"
      width={"600px"}
      steps={steps}
      onClose={handleHide}
    >
      <AddExistingLaminForm
        steps={steps}
        step={step}
        setStep={setStep}
        setErrorHeader={setErrorHeader}
        formName={formName}
        {...props}
      />
    </BMXCardModal>
  );
};

export default connectModal({ name: "AddExistingFormModal" })(
  AddExistingFormModal
);
