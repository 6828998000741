import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { ComplaintFrom } from "./ComplaintFrom/ComplaintFrom";
import { ComplaintTo } from "./ComplaintTo/ComplaintTo";
import { FormManagement } from "./FormManagement/FormManagement";

const RouterPath = [
  {
    pathName: "complaintFrom",
    Component: ComplaintFrom,
  },
  {
    pathName: "complaintTo",
    Component: ComplaintTo,
  },
  {
    pathName: "formManagement",
    Component: FormManagement,
  },
];

export const PrintDocumentRoute = () => {
  return (
    <Router>
      <Switch>
        {RouterPath.map((el) => ({
          ...el,
          pathName: `/printDocument/${el.pathName}`,
        })).map(({ pathName, Component }) => (
          <Route exact path={pathName} render={()=><Component/>} />
        ))}
      </Switch>
    </Router>
  );
};
