import i18n from "../../../../Utils/i18next/i18next";
import { NotificationManager } from "react-notifications";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { cloneDeep, set } from "lodash";

const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { updateTableRegistry, loadData, update, updateSingleRegistry, uuid } =
    props;
  const data = cloneDeep(values);

  if (values?.approvedBy?.value) {
    set(data, "approvedBy.uuid", values?.approvedBy?.value);
  }

  let payload;

  if (!update) {
    const res = await GlobalAuthFetch({
      path: `/measuring/${uuid}/verification`,
      method: "POST",
      data,
    });

    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/measuring/${uuid}/verification`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);

    return;
  }

  props.setErrorHeader(0, false);

  loadData();
  NotificationManager.success(i18n.t("global:success"));
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
  props.handleHide();
};

export const onValidate = (values, props) => {
  const errorsStep0 = {};

  if (!values.dateOfReview) {
    errorsStep0.dateOfReview = t("global:measuringDevice.errors.dateOfReview");
  }

  if (!values.nextCheckDate) {
    errorsStep0.nextCheckDate = t(
      "global:measuringDevice.errors.nextCheckDate"
    );
  }

  if (!values.approvedBy) {
    errorsStep0.approvedBy = t("global:measuringDevice.errors.approvedBy");
  }

  if (!values.result) {
    errorsStep0.result = t("global:measuringDevice.errors.result");
  }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }

  return { ...errorsStep0 };
};
